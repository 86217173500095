import {NonPersistentStorage, PersistentStorage} from '../store';
import {formatDate, getCurrentDate} from '../helper';

const SearchFilterStorage = new NonPersistentStorage();

SearchFilterStorage.set('myEventstext', `Von: ${formatDate(getCurrentDate())}`);
SearchFilterStorage.set('myEvents', [["event_dates.date", ">=", getCurrentDate()]]);

SearchFilterStorage.set('myRecommendationstext', `Von: ${formatDate(getCurrentDate())}, Geprüft: nein`);
SearchFilterStorage.set('myRecommendations', [
	["event_dates.date", ">=", getCurrentDate()],
	["recommendations.seen", "=", 0],
]);

SearchFilterStorage.set('allEventstext', `Von: ${formatDate(getCurrentDate())}`);
SearchFilterStorage.set('allEvents', [["event_dates.date", ">=", getCurrentDate()]]);

const PaginationStorage = new PersistentStorage('lraPaginationStorage');

const MediaConfirmationStorage = new PersistentStorage('mediaConfirmationStorage', sessionStorage);

export {SearchFilterStorage, PaginationStorage, MediaConfirmationStorage};