<template>
	<section>
		<AjaxTable
			endpoint="eventkind"
			headline="Veranstaltungsarten"
			searchFilterId="eventKinds"
			v-bind:displayFields="displayFields"
			v-bind:fieldKinds="fieldkinds"
			v-bind:popups="popups"
			v-bind:queryFields="queryFields"
			v-bind:initialHeader="initialHeader"
		>
			<template v-slot:description="slotProps">
				<ShortenedText :text="slotProps.content" :length="50"></ShortenedText>
			</template>
		</AjaxTable>
	</section>

</template>

<script>


export default {
	data: function(){
		return {
			initialHeader: [
				'',
				'Name',
				'Beschreibung',
			],
			queryFields: ['name',],
			popups: [
				{
					name: 'edit',
					component: 'EditEventKind',
					displayName: 'Veranstaltungsart ändern',
					params: {
						fullscreen: true,
					}
				},
				{
					name: 'create',
					component: 'EditEventKind',
					displayName: 'Veranstaltungsart erstellen',
					params: {
						fullscreen: true,
					}
				},
			],
			displayFields: [
				{
					kind: 'field',
					name: 'image.url',
					width: 15,
				},
				{
					kind: 'field',
					name: 'name',
					width: 20,
				},
				{
					kind: 'slot',
					name: 'description',
				},
			],
			fieldkinds: [
				{
					name: 'url',
					kind: 'img'
				},
			],
		};
	},
};
</script>

<style scoped>

</style>