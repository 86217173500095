<template>
	<span>
		<span class="helpIcon-icon" v-on:click="show = !show;">
			<Icon
				v-bind:name="icon"
			></Icon>
		</span>
		
		<template v-if="helpTextHtml">
			<div
				class="helpIcon-text"
				v-bind:class="{show: show}"
				v-html="helpTextHtml"
			></div>
		</template>
		<template v-else>
			<div
				class="helpIcon-text"
				v-bind:class="{show: show}"
			>{{ helpText }}</div>
		</template>
	</span>

</template>

<script>
export default {
	name: "HoverInfo",
	data: function(){
		return {
			icon: 'help_outline',
			show: false,
		};
	},
	props: {
		helpText: {
			type: String,
			default: '',
		},
		helpTextHtml: {
			type: String,
			default: '',
		},
	},
}
</script>

<style scoped>

</style>