<template>
	<AjaxTable
		endpoint="event"
		headline="Veranstaltungen"
		searchFilterId="allEvents"
		v-bind:displayFields="displayFields"
		v-bind:fieldKinds="fieldKinds"
		v-bind:popups="popups"
		v-bind:queryFields="queryFields"
		v-bind:initialHeader="initialHeader"
		idField="uId"
	>
		
		<template v-slot:releaseCheckbox="slotProps">
			<AjaxCheckbox
				:initialRecord="slotProps.content.id"
				:initialValue="slotProps.content.released"
				:params="{userGroup: sessionStorage.lraUserGroupId}"
				valueName="released"
				endpoint="toggleRelease"
				help="Freigabe"
			></AjaxCheckbox>
		</template>
		
		<template v-slot:searchComponent="slotProps">
			<AllEventSearch
				fieldName="name"
				v-bind:searchFilter="slotProps.searchFilter"
				v-bind:searchFilterId="slotProps.searchFilterId"
			></AllEventSearch>
		</template>
	
	</AjaxTable>
</template>

<script>
export default {
	data: function(){
		return {
			queryFields: ['name', 'event_dates.title'],
			initialHeader: [
				'Datum',
				'Veranstaltung',
				'Ort',
				'Ersteller',
				'Freigabe',
			],
			popups: [
				{
					name: 'edit',
					component: 'EditEvent',
					displayName: 'Veranstaltung ändern',
					params: {
						fullscreen: true,
					}
				},
				{
					name: 'create',
					component: 'EditEvent',
					displayName: 'Veranstaltung erstellen',
					params: {
						fullscreen: true,
					}
				},
				{
					name: 'recommendation',
					component: 'SuggestionPopup',
					displayName: 'Veranstaltung empfehlen',
					params: {
						fullscreen: true,
					}
				},
				{
					name: 'showDetails',
					component: 'EventDetail',
					displayName: 'Veranstaltung',
					params: {
						fullscreen: true,
					}
				},
			
			],
			displayFields: [
				{
					kind: 'field',
					name: 'date',
				},
				{
					kind: 'field',
					name: 'name',
					width: 40,
				},
				{
					kind: 'field',
					name: 'eventDates.0.location.name',
				},
				{
					kind: 'field',
					name: 'created_by_media',
					width: 10,
				},
				{
					name: 'releases',
					kind: 'slot',
					slotName: 'releaseCheckbox',
				},
			],
			fieldKinds: [
				{
					name: 'date',
					kind: 'date',
				},
				{
					name: 'created_by_media',
					kind: 'img',
				},
			],
		};
	},
	computed: {
		sessionStorage: () => sessionStorage,
	}
};
</script>

<style scoped>

</style>
