<template>
	<div id="EventDetail readonly flexbox">
		
		<div class="form-item infocard coloumn space-between">
			<label class="info-titel"> Allgemeine Infos </label>
			<p><strong> Erstellt von: </strong> {{ created }} </p> <br />
			<p><strong> Titel: </strong> {{ data.name }} </p> <br />
			<p><strong> Veranstaltungsart: </strong> {{ data.kind.name }} </p> <br />
			<p><strong> Anmeldung: </strong> {{ registration }} </p> <br />
			<p><strong> Freier Eintritt: </strong> {{ freeEntry }} </p> <br />
			<p><strong> Preis Beschreibung: </strong> <span v-html="data.price"></span></p><br />
			<p v-if="data.ticket_presale">
				<strong>Ticketshop: </strong>
				<a :href="data.ticket_presale" target="_blank">{{ data.ticket_presale }}</a>
			</p>
		</div>
		
		<!-- Eigenschaften -->
		<div class="form-item infocard coloumn space-between">
			<label class="info-titel"> Eigenschaften </label>
			<div class="row">
				<template v-if="attributes.length > 0">
					<div v-for="attr in attributes" :key="attr.title" class="column items-center">
						<img v-if="attr.title !== 'Kostenpflichtig' "
							v-bind:src="icon_url + '/' + attr.name + '/' + icon_size + '/icon.png'">
						<Icon v-else :name="attr.name" style="font-size: 94px;" />
						{{ attr.title }}
					</div>
				</template>
				<template v-else> Keine Eigenschaften gepflegt</template>
			</div>
		</div>
		
		<div class="form-item infocard coloumn space-between">
			<label class="info-titel"> Veranstalter </label>
			<p><strong> Name: </strong> {{ data.organisation }} </p> <br />
			<p><strong> Webseite: </strong> {{ data.web }} </p> <br />
			<p><strong> Email: </strong> {{ data.mail }} </p> <br />
			<p><strong> Vorwahl: </strong> {{ phoneArea }} </p><br />
			<p><strong> Telefon: </strong> {{ phoneNum }} </p><br />
		</div>
		
		<div class="form-item infocard coloumn space-between">
			<label class="info-titel"> Soziale Medien </label>
			<p><strong> Facebook: </strong> {{ data.facebook || "Nichts hinterlegt" }} </p> <br />
			<p><strong> Twitter: </strong> {{ data.instagram || "Nichts hinterlegt" }} </p> <br />
			<p><strong> Instagram: </strong> {{ data.instagram || "Nichts hinterlegt" }} </p> <br />
		</div>
		
		<div class="form-item infocard coloumn space-between">
			<label class="info-titel"> Veranstaltungen </label>
			<template v-for="eventdate in data.eventDates">
				<p :key="eventdate.id">
					<strong> <u> {{ eventdate.title }} {{ eventdate.canceled === 1? "(ABGESAGT)" : "" }} </u> </strong>
					<br />
					<span> <strong>Datum:</strong> {{ eventdate.date }} bis {{ eventdate.date_end || eventdate.date }} </span>
					<br />
					<span> <strong>Dauer:</strong> {{ getDuration(eventdate.id) }} </span>
					<br />
					<span> <strong>Ort:</strong> {{ eventdate.location.name }}  </span> <br />
					<template v-if="eventdate.location.name !== 'Online'">
						<span> <strong>Straße:</strong> {{
								eventdate.street || ""
							}} {{ eventdate.street_nr || "" }}   <br /> </span>
						<span> <strong>Zusatz:</strong> {{
								eventdate.address_supplement || ""
							}} </span> <br />
					</template>
					<span> <strong>Beschreibung:</strong> <br />
						<span v-html="eventdate.description"></span>
					</span>
					<br />
					
					<br />
				</p>
			</template>
		</div>
		
		<div class="form-item infocard row">
			<label class="info-titel"> Bilder </label>
			<template v-for="m in data.media">
				<div :key="m.id" style="text-align:center;">
					<img style="width: 200px;" :src="m.url"> <br />
					{{ m.name }}
				</div>
			</template>
		</div>
		
		<div class="form-item infocard coloumn space-between">
			<label class="info-titel"> Freigaben </label>
			<template v-for="group in releaseInfo">
				<p :key="group.id">
					<strong> {{ group.name }}: </strong>
					<Icon :name="group.released ? 'radio_button_checked' : 'radio_button_unchecked'"
						:style="group.released ? 'color:#007bff' : ''" />
					<br /> <br />
				</p>
			</template>
		</div>
	</div>
</template>

<script>
import restClient from '../../rest';

export default {
	name: "EventDetail",
	data: function(){
		return {
			attributes: [],
			icon_size: '2x',
			icon_url: 'http://events.hassberge.de/storage/icons',
			data: {
				kind: {
					name: '',
				},
			},
			phoneArea: '',
			phoneNum: '',
			releases: {},
			created: '',
		};
	},
	props: {
		event: {
			type: Number,
		},
	},
	
	computed: {
		created_by(){
		
		},
		registration(){
			return this.data.registration? "Ja" : "Nein";
		},
		freeEntry(){
			return this.data.with_cost === 0 || this.data.with_cost === null? "Ja" : "Nein";
		},
		releaseInfo(){
			const releases = [];
			if(this.releases.mainGroup){
				releases.push({
					id: this.releases.mainGroup.id,
					name: this.releases.mainGroup.name,
					released: this.releases.releases[this.releases.mainGroup.id],
				});
				this.releases.authGroups.forEach(group => {
					releases.push({
						id: group.id,
						name: group.name,
						released: this.releases.releases[group.id],
					});
				});
			}
			return releases;
		},
		
	},
	
	created: async function(){
		console.log(this);
		const res = await restClient.ajax({
			endpoint: `event/${this.event}`,
			method: 'GET',
		});
		if(res){
			this.created = res.created_by_name.split("-")[1];
			this.data = res;
			if(res.phone){
				const phoneParts = res.phone.split(' ');
				this.phoneArea = phoneParts[0] || '';
				this.phoneNum = phoneParts[1] || '';
			}
			
			this.initialData = res;
			
			const releaseData = {
				event: this.event,
			};
			
			this.releases = await restClient.ajax({
				endpoint: `eventReleases`,
				method: 'GET',
				data: releaseData
			});
			
			
		}else{
			//fehlermeldung
		}
		
		this.attributes = this.groupAttributes();
		
	},
	
	methods: {
		/**
		 * @return {array}
		 */
		groupAttributes: function(){
			let attributes = [];
			let array = [
				{title: 'Barrierefrei', name: `accessible_black`, value: this.data.accessible},
				{title: 'Nachhaltig', name: `eco_black`, value: this.data.sustainability},
				{title: 'Familienfreundlich', name: `family_restroom`, value: this.data.family_friendly},
				{title: 'Kostenpflichtig', name: `euro_symbol`, value: this.data.with_cost},
			];
			array.forEach(attribute => {
				if(attribute.value){
					attributes.push(attribute);
				}
			});
			return attributes;
		},
		/**
		 * @param {string} eventDateId
		 * @return {string}
		 */
		getDuration(eventDateId){
			for(let eventDate of this.data.eventDates){
				if(eventDate.id === eventDateId){
					if(eventDate.start && eventDate.end){
						return `${eventDate.start} - ${eventDate.end}`;
					}
					
					if(eventDate.start){
						return `ab ${eventDate.start}`;
					}
					
					if(eventDate.end){
						return `bis ${eventDate.end}`;
					}
				}
			}
			
			return 'keine Angabe';
		},
	}
	
	
}
</script>

<style scoped>

</style>