<template>
	
	<section class="formBorder">
		<div>
			<div>
				Name:
			</div>
			<div>
				<input style="width:100%" type="text" v-model.trim="data.name" placeholder="Name"/>
			</div>
		</div>
		<div>
			<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
		</div>
	</section>

</template>

<script>
const restClient = window.CalendarApp.client;

export default {
	name: "EditRegion",
	data: function(){
		return {
			data: {
				name: '',
			},
			initData: null,
		}
	},
	props: {
		id: Number,
		copy: {
			kind: Boolean,
			default: false,
		},
	},
	created: async function(){
		if(this.id){
			const res = await restClient.ajax({
				endpoint: `region/${this.id}`,
				method: 'GET',
			});
			if(res){
				this.data = res;
				this.initialData = res;
			}else{
				//fehlermeldung
			}
			
			if(this.copy){
				this.id = null;
			}
			
		}
	},
	methods: {
		save: async function(){
			const endpoint = `region${this.id? '/' + this.id : ''}`;
			const method = this.id? 'PUT' : 'POST';
			
			const result = await restClient.ajax({
				endpoint: endpoint,
				method: method,
				data: {
					name: this.data.name,
				},
			});
			
			if(result && result.id){
				this.$emit('refreshTableItem', result);
				this.$emit('closeSuccess');
			}else{
				Fehlermeldung
			}
		},
	},
}
</script>

<style scoped>

</style>