<template>
	<section class="formBorder">
		<NosNotice
			msg1="Erfolgreich gespeichert!"
			msg2="Es werden nur Gruppen mit eingetragener Email angezeigt!"
			type="success">
		</NosNotice>
		<div class="infocard row space-between w-50" style="margin:auto;">
			<label class="info-titel"> Veranstaltung empfehlen </label>
			<div class="column w-100">
				<div class="row space-between w-100">
					<label style="width: 60%;">Gruppe</label>
					<label style="width: 20%;">Empfohlen</label>
					<label style="width: 20%;">Freigabe</label>
				</div>
				<div v-for="group in user_groups" :key="group.id" class="row space-between w-100 highlight-row">
					<template v-if="group.email">
						<label class="row" style="width: 60%;">{{ group.name }}</label>
						<div style="top: 3px;width: 20%;">
							<label class="switch">
								<input type="checkbox" v-model="group.recommended" value="1">
								<span class="slider round"></span>
							</label>
						</div>
						<div style="top: 3px;width: 20%;">
							<label class="switch checkboxDisabled">
								<input type="checkbox" v-bind:checked="group.released" value="1" disabled>
								<span class="slider round"></span>
							</label>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div>
			<NosBtn icon="save" title="Speichern" bKind="save" v-on:clicked="send()"></NosBtn>
			<NosBtn title="Abbrechen" bKind="cancel" v-on:clicked="$emit('close')"></NosBtn>
		</div>
	</section>
</template>

<script>
import restClient from '../../rest';

export default {
	name: "SuggestionPopup",
	data: function(){
		return {
			user_groups: [],
		};
	},
	props: {
		id: {
			type: Number,
			default: 0,
		},
		name: {
			type: String,
			default: '',
		},
	},
	created: async function(){
		const groups = await restClient.ajax({
			endpoint: 'recommendation',
			method: 'GET',
			data: {
				event: this.id,
			}
		});
		
		groups.forEach((group, index) => {
			//groups[index].recommended = 0;
		});
		
		this.user_groups = groups;
	},
	methods: {
		send: async function(){
			const groups = [];
			this.user_groups.forEach(group => {
				if(group.recommended){
					groups.push(group.id);
				}
			});
			
			await restClient.ajax({
				endpoint: 'sendRecommendation',
				method: 'POST',
				data: {
					event: this.id,
					groups: groups,
				}
			});
			
			document.dispatchEvent(new Event('reloadAjaxTable'));
			this.$emit('close');
		},
	},
}
</script>

<style scoped>

</style>