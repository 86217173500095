<template>
	<div>
		<div style="position:relative;">
			<input
				v-bind:id="inputId"
				class="form-control"
				type="text"
				:placeholder="placeholder"
				v-model="display"
				v-on:keyup="startSearch($event)"
				v-on:type="showResult()"
				v-on:click="inputClicked()"
			/>
			<div v-if="!selectedId && display" style="position:absolute;top:7px;right:10px;">
				<Icon name="warning"></Icon>
			</div>
			<input type="hidden" v-model="selectedId" />
		</div>
		<div class="AjaxSelect-result" v-bind:class="{visible:resultVisible}" v-bind:data-from_input="inputId">
			<template v-for="item in data">
				<div
					:key="item.id"
					class="AjaxSelect-result-row"
					v-on:click="selectItem(item)"
					:title="stripTags(item.description)"
				>
					<img v-if="item.image" :src="item.image.url" :alt="item.image.name">
					
					<span>{{ item.name }}</span>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import restClient from '../../rest';
import {stripTags} from '../../html';
import {generateUniqueId} from '../../helper';

let searchTimeout;
export default {
	name: "AjaxSelect",
	data: function(){
		return {
			data: [],
			searchTimeout: null,
			display: '',
			resultVisible: false,
			selectedId: '',
			last: '',
		};
	},
	props: {
		placeholder: {
			type: String,
			default: '',
		},
		endpoint: String,
		initialDisplay: {
			type: String,
			default: '',
		},
		displayFields: Array,
		initialId: {
			default: '',
		},
		inputId: {
			type: String,
			default: () => 'a' + generateUniqueId(),
		},
		pageSize: {
			type: Number,
			default: 30,
		},
		selectedObject: {
			default: false,
		},
	},
	watch: {
		initialId: async function(newVal){
			if(newVal){
				this.selectedId = newVal;
				const result = await restClient.ajax({
					endpoint: `${this.endpoint}/${newVal}`,
					method: 'GET',
				});
				if(result && result.id){
					this.selectItem(result);
				}
			}
		}
	},
	created: async function(){
		if(this.selectedObject && this.selectedObject.id){
			this.selectItem(this.selectedObject);
		}else{
			this.selectedId = this.initialId;
			if(this.selectedId){
				const result = await restClient.ajax({
					endpoint: `${this.endpoint}/${this.selectedId}`,
					method: 'GET',
				});
				if(result && result.id){
					this.selectItem(result);
				}
			}
		}
		this.registerEvents();
	},
	destroyed: function(){
		this.detachEvents();
	},
	computed: {
		stripTags: () => stripTags,
	},
	methods: {
		registerEvents: function(){
			document.addEventListener('click', this.wasClicked)
		},
		wasClicked: function(evt){
			const input = evt.target.closest(`#${this.inputId}`);
			const list = evt.target.closest(`[data-from_input="${this.inputId}"]`);
			if(!input && !list){
				this.hideResult();
			}else{
				if(input){
					this.showResult();
				}
			}
		},
		detachEvents: function(){
			document.removeEventListener('click', this.wasClicked);
		},
		startSearch: function(evt){
			const dontSearch = [
				'Shift',
				'Tab',
				'Esc',
			];
			if(dontSearch.includes(evt.key)){
				return;
			}
			if(searchTimeout){
				clearTimeout(searchTimeout);
			}
			searchTimeout = setTimeout(this.search, 350);
		},
		inputClicked: function(){
			if(!this.data.length && !this.selectedId){
				this.search(true);
			}
		},
		search: async function(force = false){
			if(!force && this.display === this.last){
				return;
			}
			let filter = [];
			if(this.display){
				filter = {
					'name': [
						'like',
						this.display,
					]
					
				};
			}
			const result = await restClient.ajax({
				endpoint: this.endpoint,
				method: 'GET',
				data: {
					filter: filter,
					pageSize: this.pageSize,
				},
			});
			this.last = this.display;
			if(result){
				this.selectedId = '';
				this.data = result.data;
				if(this.data.length){
					this.resultVisible = true;
				}
			}
		},
		showResult: function(){
			this.resultVisible = true;
		},
		hideResult: function(){
			this.resultVisible = false;
		},
		selectItem: function(item){
			this.resultVisible = false;
			this.selectedId = item.id;
			this.renderDisplayFromItem(item);
			this.last = this.display;
			this.$nextTick(() => {
				this.$emit('itemSelected', item);
			});
		},
		renderDisplayFromItem: function(item){
			let res = '';
			for(let i of this.displayFields){
				if(item[i]){
					res += ' ' + item[i];
				}
			}
			this.display = res.substr(1);
		},
	},
}
</script>

<style scoped>

</style>