<template>
	<div>
		<img style="display:block;" class='ajaxTable-image' :src='media.url' @click="openPopup"/>
		<template v-if="popupVisible">
			<Popup
				component="MediaPopup"
				:params="{media, fullscreen: true}"
				name="Bild"
				v-on:close="closePopup()"
			></Popup>
		</template>
	</div>
</template>

<script>
export default {
	name: "MediaImage",
	data(){
		return {
			popupVisible: false,
		};
	},
	props: {
		media: {},
	},
	methods: {
		openPopup(){
			this.popupVisible = true;
		},
		closePopup(){
			this.popupVisible = false;
		}
	},
}
</script>

<style scoped>

</style>