<template>
	<div>
		<div class="row fiftyfifty">
			<div class="infocard row" style="min-height:154px;">
				<label class="info-titel">Datum</label>
				<label>Von:</label>
				<div class="form-group">
					<input type="date" v-model="date_from"/>
				</div>
				<label>Bis:</label>
				<div class="form-group">
					<input type="date" v-model="date_till"/>
				</div>
			</div>
			
			<div class="infocard column">
				<label class="info-titel">Veranstaltungsort</label>
				<div class="row">
					<label id="">Inklusive Ortsteile</label>
				</div>
				<div class="row">
					<div>
						<label class="switch">
							<input type="checkbox" v-model="includeSubLocations" value="1">
							<span class="slider round"></span>
						</label>
					</div>
					<div class="form-group">
						<AjaxSelect
							endpoint="location"
							v-bind:displayFields="['name']"
							ref="location"
							:pageSize="0"
						></AjaxSelect>
					</div>
				</div>
			</div>
		</div>
		<div class="row fiftyfifty">
			<div class="infocard row">
				<label class="info-titel">Veranstaltungsart</label>
				
				<div class="form-group">
					<AjaxSelect
						endpoint="eventkind"
						v-bind:displayFields="['name']"
						ref="eventKind"
					></AjaxSelect>
				</div>
			</div>
			
			<div class="infocard row">
				<label class="info-titel">Angelegt von</label>
				
				<div class="form-group">
					<AjaxSelect
						endpoint="usergroup"
						v-bind:displayFields="['name']"
						:pageSize="0"
						ref="usergroup"
					></AjaxSelect>
				</div>
			</div>
		</div>
		<div class="infocard row">
			<label class="info-titel">Freigabestatus</label>
			
			<div class="form-group">
				<div class="radioPair">
					<input type="radio" value="1" v-model="releaseState"/>
					<label>ja</label>
				</div>
				<div class="radioPair">
					<input type="radio" value="0" v-model="releaseState"/>
					<label>nein</label>
				</div>
				<div class="radioPair">
					<input type="radio" :value="null" v-model="releaseState"/>
					<label>beides</label>
				</div>
			</div>
		</div>
		
		
		<NosBtn icon="save" title="übernehmen" bKind="save"
			v-on:clicked="$emit('startSearch'); $emit('close');"
		></NosBtn>
	</div>
</template>

<script>
export default {
	name: "AllEventsSearchPopup",
	data: function(){
		return {
			date_from: '',
			date_till: '',
			releaseState: null,
			includeSubLocations: 0,
		};
	},
	props: {
		searchCache: {
			type: Object,
			default: () => {
				return {}
			},
		}
	},
	created: function(){
		if(this.searchCache.filter && this.searchCache.filter.length){
			setTimeout(() => {
				this.setDefaults()
			}, 200);
		}
	},
	methods: {
		createFilter: function(){
			const result = {
				filter: [],
				filterText: '',
			};
			
			if(this.date_from){
				result.filter.push(['event_dates.date', '>=', this.date_from]);
				result.filterText += `Von: ${this.date_from.split('-').reverse().join('.')}, `;
			}
			
			if(this.date_till){
				result.filter.push(['event_dates.date', '<=', this.date_till]);
				result.filterText += `Bis: ${this.date_till.split('-').reverse().join('.')}, `;
			}
			
			if(this.$refs.eventKind && this.$refs.eventKind.selectedId){
				result.filter.push(['events.kind', '=', this.$refs.eventKind.selectedId]);
				result.filterText += `Art: ${this.$refs.eventKind.display}, `;
			}
			
			if(this.$refs.location && this.$refs.location.selectedId){
				result.filter.push(['event_dates.location', '=', this.$refs.location.selectedId]);
				result.filterText += `Ort: ${this.$refs.location.display}, `;
				
				if(this.includeSubLocations){
					result.filter.push(['includeSubLocations', true]);
				}
			}
			
			if(this.releaseState !== null){
				result.filter.push(['event_releases.released', '=', this.releaseState]);
				result.filterText += `Freigabe: ${parseInt(this.releaseState)? 'ja' : 'nein'}, `;
			}
			
			if(this.$refs.usergroup && this.$refs.usergroup.selectedId){
				result.filter.push(['created_by', '=', this.$refs.usergroup.selectedId]);
				result.filterText += `Angelegt von: ${this.$refs.usergroup.display}, `;
			}
			
			
			if(result.filterText){
				result.filterText = result.filterText.substr(0, result.filterText.length - 2);
			}
			
			return result;
		},
		setDefaults: function(){
			this.searchCache.filter.forEach(arr => {
				switch(arr[0]){
					case 'event_dates.date':
						this.date_from = arr[2];
						break;
					case 'event_dates.date_end':
						this.date_till = arr[2];
						break;
					case 'events.kind':
						this.$refs.eventKind.initialId = arr[2];
						break;
					case 'event_dates.location':
						this.$refs.location.initialId = arr[2];
						break;
					case 'event_releases.released':
						this.releaseState = arr[2];
						break;
					case 'created_by':
						this.$refs.usergroup.initialId = arr[2];
						break;
				}
			});
		}
	},
}
</script>

<style scoped>

</style>