<template>
	<div class="popup-overlay" v-bind:class="id">
		<div class="popup-wrapper" v-bind:class="{fullscreen: isFullscreen, close: isClose}" v-bind:id="id">
			<nav style="height:40px">
				<div>
					{{ params.name? params.name : 'Übersicht' }}
				</div>
				<div style="display:flex; flex-direction:row">
					<div>
						<NosBtn id="toggle" icon="close_fullscreen" bKind="toggle-widescreen"
							v-on:clicked="toggleFullscreen()"></NosBtn>
					</div>
					<div>
						<NosBtn icon="close" bKind="close" v-on:clicked="$emit('close'); close()"></NosBtn>
					</div>
				</div>
			</nav>
			<hr/>
			
			<section class="popup-body">
				<component
					v-bind:is="component"
					v-bind="params"
					v-on:close="$emit('close')"
					v-on="$listeners"
					ref="innerComponent"
				></component>
			</section>
		
		</div>
	</div>
</template>

<script>
export default {
	data: function(){
		return {
			isFullscreen: false,
			/**
			 * Soll in Zukunft dafür sorgen, dass beim schließen des Fenster eine
			 * Animation ausgeführt wird. Das Popup sollte immer gerendert werden, nicht jedoch der Inhalt.
			 */
			isClose: false, fullscreenIcon: 'open_in_full',
		};
	},
	props: {
		component: String,
		params: {
			type: Object,
			default: () => {
			},
		},
		fullscreen: {
			type: Boolean,
			default: false,
		},
		id: {
			type: String,
			default: () => {
				return 'normal';
			}
		}
	},
	created: function(){
		this.isFullscreen = this.params.fullscreen;
		if(this.isFullscreen){
			this.fullscreenIcon = 'close_fullscreen';
		}
		document.addEventListener('keyup', this.escapeHandler);
	},
	destroyed: function(){
		document.removeEventListener('keyup', this.escapeHandler);
	},
	methods: {
		toggleFullscreen: function(){
			this.isFullscreen = !this.isFullscreen;
			this.isSmallscreen = !this.isSmallscreen;
			if(this.isFullscreen){
				this.fullscreenIcon = 'close_fullscreen';
			}else{
				this.fullscreenIcon = 'open_in_full';
			}
		},
		close: function(){
			this.isFullscreen = false;
			this.isSmallscreen = false;
			this.isClose = true;
		},
		escapeHandler: function(evt){
			if(evt.key === 'Escape'){
				this.$emit('close');
			}
		},
	},
};
</script>

<style scoped lang="scss">

</style>