<template>
	<label class="switch" style="top: 3px">
		<input
			type="checkbox"
			v-model="value"
			value="1"
			v-on:change="valueChanged()"
			v-bind:aria-label="help"
		/>
		<span
			class="slider round"
			v-bind:title="help"
			v-bind:aria-label="help"
		></span>
	</label>
</template>

<script>
import restClient from '../../rest';

export default {
	name: "AjaxCheckbox",
	data: function(){
		return {
			record: 0,
			value: 0,
		};
	},
	props: {
		initialRecord: {
			type: Number,
			default: 0,
		},
		initialValue: {
			type: Number,
			default: 0,
		},
		valueName: {
			type: String,
			default: 'value',
		},
		params: {
			type: Object,
			default: () => {
				return {};
			},
		},
		endpoint: {
			type: String,
			default: 'test',
		},
		help: {
			type: String,
			default: '',
		},
	},
	created: function(){
		this.record = this.initialRecord;
		this.value = this.initialValue;
	},
	watch: {
		initialRecord: async function(newVal){
			if(typeof newVal !== 'undefined'){
				this.record = newVal;
			}
		},
		initialValue: async function(newVal){
			if(typeof newVal !== 'undefined'){
				this.value = newVal;
			}
		},
	},
	methods: {
		valueChanged: async function(){
			const endpoint = `${this.endpoint}/${this.record}`;
			const body = Object.assign({}, this.params);
			body[this.valueName] = this.value;
			let result;
			try{
				result = await restClient.ajax({
					endpoint: endpoint,
					method: 'POST',
					data: body,
				});
				
				document.dispatchEvent(new Event('reloadAjaxTable'));
			}catch(e){
				this.value = !this.value;
				alert('Fehler beim Speichern');
			}
		},
	},
}
</script>

<style scoped>

</style>