<template>
	<AjaxTable
		endpoint="eventReleasesList"
		headline="Veranstaltungen"
		searchFilterId="eventReleases"
		v-bind:displayFields="displayFields"
		v-bind:fieldKinds="fieldKinds"
		v-bind:popups="popups"
		v-bind:queryFields="queryFields"
		v-bind:disableButtons="true"
		v-bind:initialHeader="initialHeader"
		ajaxTableStyle="releases"
	>
		<template v-slot:searchComponent="slotProps">
			<EventReleaseSearch fieldName="name"></EventReleaseSearch>
		</template>
		
		<template v-slot:releaseCheckbox="{content, index}">
			<AjaxCheckbox
				:initialRecord="content[index].event"
				:initialValue="content[index].released"
				:params="{userGroup: content[index].userGroup}"
				valueName="released"
				endpoint="toggleRelease"
			></AjaxCheckbox>
		</template>
	</AjaxTable>
</template>

<script>
export default {
	name: "EventReleaseIndex",
	data: function(){
		return {
			queryFields: [],
			popups: [],
			displayFields: [
				{
					kind: 'field',
					name: 'name',
				},
				{
					kind: 'field',
					name: 'eventDate',
				}
			],
			fieldKinds: [
				{
					name: 'eventDate',
					kind: 'date',
				},
			],
			initialHeader: [
				'Veranstaltung',
				'Datum',
			],
		};
	},
	created: function(){
		//default eigene Gruppe als Header setzen und displays erweitern
		this.initialHeader.push(sessionStorage.lraUserGroupName);
		this.displayFields.push({
			name: 'releases',
			kind: 'slot',
			slotName: 'releaseCheckbox',
			index: 0,
		});
	},
}
</script>

<style scoped>

</style>