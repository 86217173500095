<template>
	<div>
		<div class="infocard">
			
			<label class="info-titel">Kalender Webseite
				<Required/>
			</label>
			<div class="form-group" style="width: 100%;">
				<input id="domain" type="text" v-model.trim="data.domain" aria-describedby="Domain" maxlength="191">
				<span class="helpertext"> URL der Webseite, auf dem der Kalender angezeigt werden soll </span>
			</div>
		</div>
		<div class="infocard">
			<label class="info-titel">Api Schlüssel:&nbsp;<span v-on:click="copyApiKey($event)"
				title="Api Key kopieren"><Icon
				v-bind:iconStyle="{'font-size': '16px', cursor: 'pointer'}"
				name="content_copy"></Icon></span>
			</label>
			<div class="form-group" style="width: 100%;">
				<input id="apiKey" type="text" disabled v-model.trim="data.key" aria-describedby="api key">
				<span class="helpertext"> Der API Schlüssel wird beim speichern automatisch generiert </span>
			</div>
		
		</div>
		<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
	</div>
</template>

<script>
import restClient from '../../rest';

export default {
	name: "EditApiUser",
	data: function(){
		return {
			data: {
				id: null,
				domain: '',
				key: '',
			},
		};
	},
	props: {
		id: Number,
		copy: {
			kind: Boolean,
			default: false,
		},
	},
	created: async function(){
		if(this.id){
			const res = await restClient.ajax({
				endpoint: `apiUser/${this.id}`,
				method: 'GET',
			});
			if(res){
				this.data = res;
				this.initialData = res;
			}else{
				//fehlermeldung
			}
			
			if(this.copy){
				this.id = null;
			}
			
		}else{
		
		}
	},
	methods: {
		save: async function(){
			const endpoint = `apiUser${this.id? '/' + this.id : ''}`;
			const method = this.id? 'PUT' : 'POST';
			const result = await restClient.ajax({
				endpoint: endpoint,
				method: method,
				data: {
					domain: this.data.domain,
					key: this.data.key,
				},
			});
			
			if(result && result.id){
				this.$emit('refreshTableItem', result);
				this.$emit('closeSuccess');
			}else{
				if(result && result.errors){
					alert(result.errors.join("\n"));
				}
			}
		},
		copyApiKey: function(evt){
			evt.target.style.color = '#28a745';
			setTimeout(() => {
				evt.target.style.color = 'inherit';
			}, 500);
			const el = document.createElement('textarea');
			el.value = this.data.key;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
		},
	},
}
</script>

<style scoped>

</style>