<template>
	<div>
		<NosBtn
			bKind="edit"
			icon="visibility"
			@clicked="openPopup"
			help="Verwendung"
		/>
		<template v-if="popupVisible">
			<Popup
				component="MediaUsage"
				:params="{id, fullscreen: true}"
				name="Bild"
				v-on:close="closePopup()"
			></Popup>
		</template>
	</div>
</template>

<script>
export default {
	name: "MediaUsageButton",
	data(){
		return {
			popupVisible: false,
		}
	},
	props: {
		id: {
			type: Number,
		},
	},
	methods: {
		closePopup(){
			this.popupVisible = false;
		},
		openPopup(){
			this.popupVisible = true;
		},
	},
}
</script>

<style scoped>

</style>