<template>
	<div style="width: 100%;overflow:auto;">
		<template v-if="creator.name">
			<p>Ersteller: <span>{{ creator.name }}</span></p>
			<br/>
		</template>
		<img :src="media.url"/>
	</div>
</template>

<script>
import restclient from '../../rest';

export default {
	name: "MediaPopup",
	data(){
		return {
			creator: null,
		};
	},
	props: {
		media: {
			type: Object,
		},
	},
	async created(){
		const result = await restclient.ajax({
			endpoint: `mediaCreator/${this.media.id}`,
			method: 'GET',
		});
		if(result.data){
			this.creator = result.data;
		}
	}
}
</script>

<style scoped>

</style>