<template>
	<div v-if="show" class="NosNotice" v-bind:class="type">
		<div class="head">
			<p> {{ noticeTitel }} </p>
		</div>
		<div class="body column">
			<p> {{ msg1 }} </p>
			<br/>
			<p> {{ msg2 }} </p>
		</div>
	</div>
</template>

<script>
export default {
	name: "NosNotice",
	data: function(){
		return {
			titel: '',
			show: true,
		}
	},
	props: {
		msg1: {type: String,},
		msg2: {type: String,},
		type: {type: String,},
	},
	created: function(){
	
	},
	
	computed: {
		noticeTitel: function(){
			let result;
			switch(this.type){
				case 'error':
					result = 'Fehler';
					break;
				
				case 'info':
					result = 'Info';
					break;
				
				case 'success':
				default:
					result = 'Erfolg';
					break;
			}
			
			return result;
		}
	},
	
	methods: {
		getTitel: function(){
		
		}
	},
}
</script>