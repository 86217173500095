<template>
	<section style="width:100%;">
		<p>Filter: {{ filterText? filterText : 'Kein Filter ausgewählt' }}</p>
		<div class="infocard row" style="min-height:154px;">
			<label class="info-titel">Gruppe</label>
			<div class="form-group">
				<select v-model="selectedGroup">
					<option
						v-for="group in groups"
						:key="group.id"
						:value="group.id"
					>{{ group.name }}
					</option>
				</select>
			</div>
		</div>
		<div class="infocard row" style="min-height:154px;">
			<label class="info-titel">Sortierung</label>
			<div class="form-group">
				<div>
					<input id="sortOrderDate" type="radio" value="date" v-model="sortOrder"
						style="width:unset; display:inline;"/>
					<label for="sortOrderDate">
						Datum
					</label>
				</div>
				<div>
					<input id="sortOrderCategory" type="radio" value="category" v-model="sortOrder"
						style="width:unset; display:inline;"/>
					<label for="sortOrderCategory">
						Kategorie
					</label>
				</div>
			</div>
		</div>
		<div style="display:flex; flex-direction: row;justify-content: space-around;">
			<div style="position: relative;">
				<NosBtn icon="save" title="XML Download" bKind="edit"
					v-on:clicked="downloadAsXml()"
				></NosBtn>
			</div>
			
			<div style="position: relative;">
				<NosBtn icon="save" title="CSV Download" bKind="edit"
					v-on:clicked="downloadAsCsv()"
				></NosBtn>
			</div>
		</div>
	</section>
</template>

<script>

import restClient from '../../rest';
import {downloadFile} from '../../download';

export default {
	name: "DownloadPopup",
	data: function(){
		return {
			groups: [],
			selectedGroup: null,
			sortOrder: null,
		};
	},
	props: {
		filter: {
			type: Object,
			default: () => {
				return {
					filter: [],
				};
			},
		},
		filterText: {
			type: String,
			default: '',
		}
	},
	created: function(){
		this.loadGroups();
	},
	methods: {
		loadGroups: async function(){
			const res = await restClient.ajax({
				method: 'GET',
				endpoint: `usergroup/${sessionStorage.lraUserGroupId}`,
				data: {
					withChildren: true,
				},
			});
			
			if(res){
				res.userGroups.unshift({
					id: res.id,
					name: res.name,
				});
				
				this.groups = res.userGroups;
			}
		},
		downloadAsXml: async function(){
			if(!this.selectedGroup){
				alert('Es muss eine Gruppe ausgewählt werden');
				return;
			}
			if(!this.sortOrder){
				alert('Es muss eine Sortierung ausgewählt werden');
				return;
			}
			
			let filter = [];
			
			if(this.filter && this.filter.filter){
				filter = this.filter.filter;
			}else if(this.filter){
				filter = this.filter
			}
			
			const result = await restClient.ajax({
				method: 'GET',
				endpoint: 'downloadAsXML',
				data: {
					filter: filter,
					group: this.selectedGroup,
					sortOrder: this.sortOrder,
				},
				download: true,
			});
			
			if(result){
				downloadFile('veranstaltungsexport.xml', result);
			}else{
				alert('fehler beim download');
			}
			
		},
		downloadAsCsv: async function(){
			if(!this.selectedGroup){
				alert('Es muss eine Gruppe ausgewählt werden');
				return;
			}
			if(!this.sortOrder){
				alert('Es muss eine Sortierung ausgewählt werden');
				return;
			}
			
			let filter = [];
			
			if(this.filter && this.filter.filter){
				filter = this.filter.filter;
			}else if(this.filter){
				filter = this.filter
			}
			
			const result = await restClient.ajax({
				method: 'GET',
				endpoint: 'downloadAsCSV',
				data: {
					filter: filter,
					group: this.selectedGroup,
					sortOrder: this.sortOrder,
				},
				download: true,
			});
			
			if(result){
				downloadFile('veranstaltungsexport.csv', result);
			}else{
				alert('fehler beim download');
			}
			
		},
	},
}
</script>

<style scoped>

</style>