<template>
	<div>
		<NosBtn icon="visibility" bKind="edit" v-on:clicked="showEventDetails()" help="Details"></NosBtn>
		<template v-if="popupVisible">
			<Popup
				component="EventDetail"
				:params="{event: event, fullscreen: true, name: 'Veranstaltungsdetails'}"
				v-on:close="closePopup()"
			></Popup>
		</template>
	</div>
</template>

<script>
export default {
	name: "EventDetailButton",
	data: function(){
		return {
			popupVisible: false,
		};
	},
	props: {
		event: {
			type: Number,
		}
	},
	methods: {
		showEventDetails: function(){
			this.popupVisible = true;
		},
		closePopup: function(){
			this.popupVisible = false;
		},
	},
}
</script>

<style scoped>

</style>