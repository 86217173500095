<template>
	<input
		v-on:keyup="$parent.startSearch(getSearchFilter())"
		type="text"
		v-model="searchString"
		placeholder="Veranstaltungen durchsuchen"
	/>
</template>

<script>
export default {
	name: "EventSearch",
	data: function(){
		return {
			searchString: '',
		};
	},
	created: function(){
	
	},
	methods: {
		getSearchFilter: function(){
			return [];
		},
	},
}
</script>

<style scoped>

</style>