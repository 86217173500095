<template>
	<section>
		<AjaxTable
			endpoint="media"
			headline="Bilder"
			searchFilterId="media"
			v-bind:displayFields="displayFields"
			v-bind:fieldKinds="fieldkinds"
			v-bind:popups="popups"
			v-bind:allowCreate="false"
			v-bind:allowEdit="false"
			v-bind:allowCopy="false"
			v-bind:deleteMsg="'Das Bild welches Sie löschen möchten kann mit mehreren Veranstaltungen verknpüft sein, möchten Sie es dennoch löschen?'"
		>
			<template v-slot:mediaPopup="slotProps">
				<MediaImage
					v-bind:media="slotProps.content"
				></MediaImage>
			</template>
			
			<template v-slot:mediaUsage="slotProps">
				<MediaUsageButton
					:id="slotProps.content.id"
				/>
			</template>
		
		</AjaxTable>
	</section>
</template>

<script>
export default {
	name: "MediaIndex",
	data: function(){
		return {
			popups: [
				{
					name: 'edit',
					component: 'EditApiUser',
					displayName: 'Api Benutzer ändern',
					params: {}
				},
			],
			displayFields: [
				/*
				{
					kind: 'field',
					name: 'url',
				},
				*/
				{
					name: 'mediaPopup',
					kind: 'slot',
					slotName: 'mediaPopup',
				},
				{
					name: 'mediaUsage',
					kind: 'slot',
					slotName: 'mediaUsage',
				},
				{
					kind: 'field',
					name: 'name',
				},
			],
			fieldkinds: [
				{
					name: 'url',
					kind: 'img',
				},
			],
		};
	},
}
</script>

<style scoped>

</style>