<template>
	<span v-bind:title="textList" style="cursor: help">
		{{ shortTextList }}
	</span>
</template>

<script>
export default {
	name: "ListText",
	props: {
		list: {
			type: Object,
			default: () => {
				return {};
			},
		},
		fieldName: String,
	},
	computed: {
		
		commaTextList: function(){
			let string = '';
			this.list.content.forEach(obj => {
				if(this.fieldName && obj[this.fieldName]){
					string += `${obj[this.fieldName]}, `;
				}else{
					string += `${obj}, `;
				}
			});
			if(string){
				// entfernt das letzte Komma
				string = string.substr(0, string.length - 2);
			}
			return string;
		},
		textList: function(){
			let string = "";
			/** @notice functioniert noch nicht 100%, da scheinbar das Leerzeichen vor
			 * z.B. "Lebensregion Plus" kein normales Leerzeichen ist und somit nicht rausgefiltert wird.  */
			let withoutspace = this.commaTextList.replace(", ", ",");
			let array = withoutspace.split(",");
			array.forEach(element => {
				string += `- ${element}\n`;
			});
			
			return string.substr(0, string.length - 5);
		},
		shortTextList: function(){
			return this.shortenText(this.commaTextList, 70).replace("\n", "<br/>");
		},
	},
	
	methods: {
		shortenText: function(text, len){
			const div = document.createElement('div');
			div.innerHTML = text;
			if(div.children.length > 0){
				let parts = "";
				div.children.forEach(el => {
					parts += el.innerText + "\n";
				});
				text = parts;
			}else{
				text = div.innerText;
			}
			if(text.length <= len){
				return text;
			}else{
				let forLen = len;
				if(text.substr(len, len + 1) !== ' '){
					const sub = text.substr(0, len);
					forLen = sub.lastIndexOf(' ');
					if(forLen === -1){
						forLen = len;
					}
				}
				return `${text.substr(0, forLen)}...`;
			}
		},
	}
}
</script>

<style scoped>

</style>