<template>
	<div class="MediaSelect">
		
		
		<Popup v-if="popupOpen"
			component="MediaSelectPopup"
			v-bind:params="{mediaGroup:this.mediaGroup}"
			v-on:close="popupOpen = false"
			@imageSelected="imageSelected($event)"
			v-bind:id="styleId">
		</Popup>
		
		<div v-if="type === 'default'" class="default column space-between">
			<div class="row title" v-bind:style="hasError? 'color:red;' : ''">
				{{ name }}
			</div>
			<div class="row center imgbox">
				<img v-if="imageUrl" v-bind:src="imageUrl"/>
			</div>
			<div class="row space-between">
				<NosBtn
					title="hochladen"
					bKind="primary"
					v-on:clicked="upload()"
					help="Bild hochladen"
				></NosBtn>
				<NosBtn
					title="auswählen"
					bKind="secondary"
					v-on:clicked="popupOpen = true"
					help="Bild auswählen"
				></NosBtn>
			</div>
		</div>
		
		<div v-else-if="type === 'row'" class="mediaSelect-row row">
			<div style="width: 15%">
				<img class="img" v-if="imageUrl" v-bind:src="imageUrl" v-on:click="download()"/>
				<img class="img" v-else v-bind:src="errorImage"/>
			</div>
			<div class="title" v-bind:style="hasError? 'color:red;' : ''">
				<div>{{ name }}</div>
				<div v-if="fileNameExists" style="padding-top: 8px;">Ein Bild mit diesem Namen existiert bereits</div>
			</div>
			<div style="width: 15%">
				<span v-if="fileSize > 0">{{ humanFileSize(fileSize) }}</span>
			</div>
			<div>
				<NosBtn
					title="hochladen"
					bKind="primary"
					v-on:clicked="upload()"
					help="Bild hochladen"
				></NosBtn>
			</div>
			<div>
				<NosBtn
					title="auswählen"
					bKind="secondary"
					v-on:clicked="popupOpen = true"
					help="Bild auswählen"
				></NosBtn>
			</div>
			<template v-if="showDelete">
				<div>
					<NosBtn
						icon="delete"
						bKind="danger"
						v-on:clicked="$emit('removemedia', loopid)"
						help="Bild entfernen"
					></NosBtn>
				</div>
			</template>
		</div>
	
	
	</div>

</template>

<script>

import downloadImage from '../../image';
import restClient from '../../rest';

const confirmationText = 'Hiermit bestätige ich verbindlich, dass ich urheberrechtlich zur Verwendung der ausgewählten Bilddatei berechtigt bin. Mir ist bewusst, dass ich im Falle der schadensersatzrechtlichen Inanspruchnahme des Plattformbetreibers wegen einer Urheberrechtsverletzung in Regress genommen werden kann.';

export default {
	name: "MediaSelect",
	data: function(){
		return {
			kind: null,
			uploadedFile: null,
			id: null,
			imageUrl: null,
			name: '',
			popupOpen: false,
			fileSize: 0,
			maxFileSize: 2000000,
			hasError: false,
			errorImage: '',
			fileNameExists: false,
		};
	},
	props: {
		loopid: '',
		showDelete: {
			type: Boolean,
			default: () => {
				return true;
			},
		},
		type: {
			type: String,
			default: () => {
				return "default"
			},
		},
		image: {
			type: Object,
			default: () => {
				return {id: null, url: '', name: ''}
			},
		},
		mediaGroup: {
			type: String,
			default: 'eventkind',
		},
		styleId: {
			type: String,
			default: 'media-select-popup',
		},
	},
	watch: {
		image: function(newValue){
			if(newValue){
				this.id = newValue.id;
				this.imageUrl = newValue.url? newValue.url.replace('localhost/', 'localhost:8000/') : '';
				this.kind = 'api';
				this.name = newValue.name;
			}
		}
	},
	created: function(){
		if(this.image.id){
			this.id = this.image.id;
			this.imageUrl = this.image.url.replace('localhost/', 'localhost:8000/');
			this.kind = 'api';
			this.name = this.image.name;
		}
	},
	methods: {
		upload: function(){
			
			
			const input = document.createElement('input');
			input.type = 'file';
			
			input.onchange = (evt) => {
				this.kind = 'upload';
				this.id = null;
				this.uploadedFile = evt.target.files[0];
				this.name = this.uploadedFile.name;
				const reader = new FileReader();
				reader.onload = () => {
					if(this.uploadedFile.size > this.maxFileSize){
						this.reset();
						this.showError('size');
					}else{
						const confirmation = confirm(confirmationText);
						
						if(!confirmation){
							return;
						}
						const imageTest = new Image();
						
						imageTest.onload = () => {
							if(!imageTest.naturalHeight || !imageTest.naturalWidth){
								this.showError('load');
							}
							const format = imageTest.naturalHeight / imageTest.naturalWidth;
							if(format <= 2){
								this.hasError = false;
								this.imageUrl = reader.result;
								this.fileSize = this.uploadedFile.size;
								this.checkFileName();
							}else{
								this.showError('format')
							}
						}
						
						imageTest.src = reader.result;
					}
				}
				
				reader.readAsDataURL(this.uploadedFile);
			}
			
			input.click();
		},
		checkFileName: async function(){
			const result = await restClient.ajax({
				endpoint: `media`,
				method: 'GET',
				data: {
					filter: [
						['file_name', '=', this.name],
					],
				},
			});
			
			this.fileNameExists = result.meta.total > 0;
		},
		download: function(){
			downloadImage(this.imageUrl, this.name);
		},
		showError: function(kind = 'size'){
			this.hasError = true;
			switch(kind){
				case 'size':
					this.name = `Bild zu groß (max. ${this.humanFileSize(this.maxFileSize)})`;
					break;
				case 'format':
					this.name = `Bild hat falsches Seitenformat`;
					break;
				default:
					this.name = 'Fehler beim Laden des Bildes';
			}
		},
		imageSelected: function(image){
			this.kind = 'api';
			this.id = image.id;
			this.name = image.name;
			this.imageUrl = image.url;
			this.popupOpen = false;
			this.hasError = false;
			this.fileSize = 0;
		},
		humanFileSize: function(bytes, si = true, dp = 1){
			const thresh = si? 1000 : 1024;
			
			if(Math.abs(bytes) < thresh){
				return bytes + ' B';
			}
			
			const units = si
				? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
				: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
			let u = -1;
			const r = 10 ** dp;
			
			do{
				bytes /= thresh;
				++u;
			}while(Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
			
			
			return bytes.toFixed(dp) + ' ' + units[u];
		},
		reset: function(){
			const defaultValues = {
				kind: null,
				uploadedFile: null,
				id: null,
				imageUrl: null,
				name: '',
				popupOpen: false,
				fileSize: 0,
			};
			
			for(let i in defaultValues){
				this[i] = defaultValues[i];
			}
		},
	},
}
</script>

<style scoped>

</style>