<template>
	<div class="ajaxTable-wrapper" v-bind:class="ajaxTableStyle">
		
		<template v-if="getActivePopup()">
			<Popup
				:component="activePopup.component"
				:params="activePopup.params"
				v-on:close="closePopup()"
				v-on:closeSuccess="closeSuccess()"
				v-on:openPopUp="openPopup($event)"
				v-on:refreshTableItem="refreshTableItem($event)"
			></Popup>
		</template>
		
		<template v-if="$scopedSlots.searchComponent">
			<div class="ajaxTable-search">
				<slot
					name="searchComponent"
					v-bind:searchFilter="searchFilter"
					v-bind:searchFilterId="searchFilterId"
				></slot>
			</div>
		</template>
		<template v-else>
			<div class="ajaxTable-search">
				<input type="text"
					v-bind:placeholder="`${headline} durchsuchen`" v-model="searchString"
					@keyup="startSearch()"
				/>
			</div>
		</template>
		<template v-if="!disableButtons">
			<NosBtn v-if="allowCreate" icon="add" bKind="create" v-on:clicked="create()" help="Erstellen"></NosBtn>
		</template>
		
		<!-- Table row -->
		<section class="ajaxTable-table" v-bind:class="ajaxTableStyle">
			<div class="ajaxTable-row header-row">
				<template v-if="header.length">
					<div class="col-row" v-bind:style="{width: disableButtons ? '100%' : '89%'}">
						<div v-for="(headertext, index) in header"
							:key="index"
							class="field"
							v-bind:style="`width: ${getFieldWidth(displayFieldsVar[index])}%`"
						>
							{{ headertext }}
						</div>
					</div>
				</template>
				<!-- kleiner Hack um header und wegen alignment zw header und content -->
				<template v-if="!disableButtons">
					<div style="visibility: hidden;">
						<NosBtn icon="edit" bKind="edit"></NosBtn>
					</div>
					<div style="visibility: hidden;">
						<NosBtn icon="edit" bKind="edit"></NosBtn>
					</div>
					<div style="visibility: hidden;">
						<NosBtn icon="edit" bKind="edit"></NosBtn>
					</div>
				</template>
			</div>
			<template v-if="data.length > 0">
				<div
					v-for="(item, index) in data"
					class="ajaxTable-row"
					v-bind:key="item[idField]"
					v-bind:class="[(endpoint === 'event' || endpoint === 'myEvents') && item.created_by !== userGroup ? 'row-grey' : '']"
				>
					<div class="col-row" v-bind:style="{width: disableButtons ? '100%' : '89%'}">
						<template v-for="field in displayFieldsVar">
							<div v-bind:key="field.name" v-if="field.kind === 'field'" class="field"
								v-html="renderField(field, item)"
								v-bind:style="`width: ${getFieldWidth(field)}%; overflow:hidden;`">
							</div>
							<div
								v-else-if="field.kind === 'slot'" class="field"
								v-bind:key="typeof field.index  !== 'undefined'? `${field.name}${field.index}` : field.name"
								v-bind:style="`width: ${getFieldWidth(field)}%; overflow:hidden;`">
								<slot
									v-bind:name="field.slotName? field.slotName : field.name"
									:content="item[field.name] ? item[field.name] : item"
									:index="typeof field.index  !== 'undefined' ? field.index : index"
								></slot>
							</div>
						</template>
					</div>
					<template v-if="!disableButtons">
						<template
							v-if="(endpoint === 'myEvents') && item.created_by !== userGroup">
							<div>
								<NosBtn
									icon="visibility"
									bKind="edit"
									help="Details"
									v-on:clicked="showEventDetails(item.id)"
								></NosBtn>
							</div>
							<div style="visibility: hidden;">
								<NosBtn icon="edit" bKind="edit"></NosBtn>
							</div>
							<div style="visibility: hidden;">
								<NosBtn icon="edit" bKind="edit"></NosBtn>
							</div>
						
						</template>
						<template v-else>
							<div>
								<NosBtn v-if="allowEdit" icon="edit" bKind="edit" v-on:clicked="edit(item.id)"
									help="Bearbeiten"></NosBtn>
							</div>
							<div>
								<NosBtn v-if="allowCopy" icon="file_copy" bKind="edit" v-on:clicked="copy(item.id)"
									help="Kopieren"></NosBtn>
							</div>
							<div>
								<NosBtn v-if="allowDelete" icon="delete" bKind="danger" v-on:clicked="remove(item.id)"
									help="Löschen"></NosBtn>
							</div>
						</template>
					</template>
				</div>
			</template>
			<template v-else-if="initialLoadDone">
				<div class="no-result">
					<p>Kein Ergebnis gefunden</p>
				</div>
			</template>
		</section>
		
		<!-- Table Footer -->
		<section class="ajxaTable-pagination">
			<NosBtn
				icon="first_page"
				bKind="secondary"
				help="Erste Seite"
				v-on:clicked="toFirstPage()"
				v-bind:disabled="page === 1">
			</NosBtn>
			<NosBtn
				icon="navigate_before"
				bKind="secondary"
				help="Vorherige Seite"
				v-on:clicked="prevPage()"
				v-bind:disabled="!prev">
			</NosBtn>
			<p v-if="perPage"> Seite: {{ page }} von {{ lastPage }} </p>
			|
			<span v-if="pageSize == 1"> Zeile: </span>
			<span v-else> Zeilen: </span>
			<select class="form-control" style="width:65px !important" v-model="pageSize"
				v-on:change="changePaginationLength($event)">
				<option
					v-for="i in Array.from({length:100}).map((v,j) => j+1).filter((v) => v%5 === 0)"
					:value="i"
					:key="i"
				>{{ i }}
				</option>
			</select>
			<NosBtn
				icon="navigate_next"
				bKind="secondary"
				help="Nächste Seite"
				v-on:clicked="nextPage()"
				v-bind:disabled="!next">
			</NosBtn>
			<NosBtn
				icon="last_page"
				bKind="secondary"
				help="Letzte Seite"
				v-on:clicked="toLastPage()"
				v-bind:disabled="page >= lastPage">
			</NosBtn>
		</section>
	</div>
</template>

<script>
import {SearchFilterStorage, PaginationStorage} from '../storage';
import {generateUniqueId} from '../../helper';
import restClient from '../../rest';


export default {
	data: function(){
		return {
			page: 0,
			data: [],
			prev: null,
			next: null,
			perPage: 0,
			lastPage: 0,
			view: 'normal',
			activePopup: false,
			searchString: '',
			searchIntervall: '',
			pageSize: 10,
			displayFieldsVar: [],
			header: [],
			userGroup: 0,
			searchFilter: [],
			initialLoadDone: false,
		};
	},
	props: {
		endpoint: String,
		headline: String,
		displayFields: {
			type: Array,
			default: () => [],
		},
		ajaxTableStyle: {
			type: String,
			default: "",
		},
		slots: {
			type: Array,
			default: () => [],
		},
		fieldKinds: {
			type: Array,
			default: () => [],
		},
		popups: {
			type: Array,
			default: () => [],
		},
		queryFields: {
			type: Array,
			default: () => [],
		},
		disableButtons: {
			type: Boolean,
			default: false,
		},
		initialHeader: {
			type: Array,
			default: () => [],
		},
		defaultFilter: {
			type: Array,
			default: () => [],
		},
		queryParams: {
			type: Object,
			default: () => {
				return {};
			},
		},
		searchFilterId: {
			type: String,
			default: () => `filter${generateUniqueId()}`,
		},
		idField: {
			type: String,
			default: 'id',
		},
		allowEdit: {
			type: Boolean,
			default: true,
		},
		allowCopy: {
			type: Boolean,
			default: true,
		},
		allowCreate: {
			type: Boolean,
			default: true,
		},
		allowDelete: {
			type: Boolean,
			default: true,
		},
		deleteMsg: {
			type: String,
			default: "Datensatz wirklich löschen?",
		},
	},
	
	created: async function(){
		document.addEventListener('reloadAjaxTable', this.evtLoadPage);
		this.searchFilter = this.defaultFilter;
		this.userGroup = parseInt(sessionStorage.lraUserGroupId);
		this.header = this.initialHeader;
		this.displayFieldsVar = this.displayFields;
		
		if(PaginationStorage.has(this.searchFilterId)){
			this.pageSize = PaginationStorage.get(this.searchFilterId);
		}
		
		this.popups.push({
			name: '',
			component: 'SuccessPopup',
			displayName: '',
			params: {},
		});
		this.popups.forEach(popup => {
			popup.active = false;
		});
		const cachedFilter = SearchFilterStorage.get(this.searchFilterId);
		if(cachedFilter){
			if(!Array.isArray(cachedFilter) || cachedFilter.length > 0){
				this.searchFilter = cachedFilter;
			}
		}
		this.loadPage();
	},
	
	destroyed: function(){
		document.removeEventListener('reloadAjaxTable', this.evtLoadPage);
	},
	
	computed: {
		fixedLengthWidth: function(){
			let width = 0;
			this.displayFieldsVar.forEach(field => {
				if(field.width){
					width += field.width;
				}
			});
			
			return width;
		},
		fixedLengthCount: function(){
			let count = 0;
			this.displayFieldsVar.forEach(field => {
				if(field.width){
					count++;
				}
			});
			
			return count;
		},
	},
	methods: {
		getFieldWidth: function(field){
			if(field && field.width){
				return field.width;
			}else{
				return 100 / (this.displayFieldsVar.length - this.fixedLengthCount)
					- (this.fixedLengthWidth / (this.displayFieldsVar.length - this.fixedLengthCount));
			}
		},
		refreshTableItem: function(res){
			let done = false;
			for(let index in this.data){
				if(this.data.hasOwnProperty(index) && this.data[index].id === res.id){
					this.data[index] = res;
					done = true;
					break;
				}
			}
			
			if(!done){
				this.loadPage();
			}
		},
		getActivePopup: function(){
			for(let p of this.popups){
				if(p.active){
					this.activePopup = p;
					return true;
				}
			}
			this.activePopup = false;
			return false;
		},
		openPopup: function(evt){
			this.popups.forEach(popup => {
				if(popup.name === evt.name){
					popup.active = true;
					popup.params = Object.assign(evt, {
						name: popup.displayName,
					});
				}else{
					popup.active = false;
				}
			});
			this.$forceUpdate();
		},
		closeSuccess: function(){
			this.closePopup();
			this.popups.forEach((p) => {
				if(p.component === 'SuccessPopup'){
					p.active = true;
					p.params = Object.assign({}, {
						name: p.displayName,
					});
				}
			});
			this.$forceUpdate();
		},
		closePopup: function(){
			this.popups.forEach(p => {
				p.active = false;
			});
			this.activePopup = false;
		},
		renderField: function(name, content){
			const orgName = name;
			if(name.name.includes('.')){
				const parts = name.name.split('.');
				const last = parts.pop();
				parts.forEach(n => {
					if(content[n]){
						content = content[n];
					}
				});
				name = last;
			}else{
				name = name.name;
			}
			for(let fieldkind of this.fieldKinds){
				if(fieldkind.name === name){
					switch(fieldkind.kind){
						case 'img':
							if(content[name]){
								return `<img style="display:block;" class='ajaxTable-image' src='${content[name]}' />`;
							}else{
								return '';
							}
							
							break;
						case 'gps':
							return `<span>Lat: ${content[name].lat} & Lng: ${content[name].lng}</span>`;
							break;
						case 'date':
							if(content[name]){
								return content[name].split('-').reverse().join('.');
							}else{
								return '';
							}
							break;
						
					}
					break;
				}
			}
			return content[name];
		},
		evtLoadPage: function(){
			this.loadPage();
		},
		loadPage: async function(searchFilter = false){
			const body = {
				page: this.page || 1,
				pageSize: this.pageSize,
			};
			const query = {};
			let useQuery = false;
			
			if(!searchFilter && this.searchFilter){
				searchFilter = this.searchFilter;
			}
			
			
			if(searchFilter){
				if(Array.isArray(searchFilter) && searchFilter.length){
					body.filter = searchFilter;
				}else{
					for(let i in searchFilter){
						if(searchFilter.hasOwnProperty(i)){
							body[i] = searchFilter[i];
						}
					}
				}
			}else{
				if(this.searchString){
					this.queryFields.forEach((fieldName) => {
						query[fieldName] = ['like', this.searchString];
						useQuery = true;
					});
				}
				if(useQuery){
					body.filter = query;
				}
			}
			
			if(body.filter){
				SearchFilterStorage.set(this.searchFilterId, JSON.parse(JSON.stringify(body.filter)));
			}
			
			for(let key in this.queryParams){
				body[key] = this.queryParams[key];
			}
			
			const result = await restClient.ajax({
				endpoint: this.endpoint,
				method: 'GET',
				data: body,
			});
			
			if(result){
				if(result.data && result.data.length === 0 && this.page > 1){
					return this.prevPage();
				}
				this.page = result.meta.current_page;
				this.prev = result.links.prev? result.meta.current_page - 1 : null;
				this.next = result.links.next? result.meta.current_page + 1 : null;
				this.perPage = result.meta.per_page;
				this.lastPage = result.meta.last_page;
				this.data = result.data;
				this.$forceUpdate();
			}
			this.initialLoadDone = true;
		},
		prevPage: async function(){
			this.page--;
			this.loadPage();
		},
		toFirstPage: async function(){
			this.page = 1;
			this.loadPage();
		},
		nextPage: async function(){
			this.page++;
			this.loadPage();
		},
		toLastPage: async function(){
			this.page = this.lastPage? this.lastPage : 1;
			this.loadPage();
		},
		create: function(){
			this.popups.forEach(popup => {
				if(popup.name === 'create'){
					popup.active = true;
					popup.params = Object.assign(popup.params || {}, {
						name: popup.displayName,
					});
				}else{
					popup.active = false;
				}
			});
			this.$forceUpdate();
		},
		edit: async function(id){
			this.popups.forEach(popup => {
				if(popup.name === 'edit'){
					popup.params = Object.assign(popup.params || {}, {
						id: id,
						name: popup.displayName,
					});
					popup.params.copy = false;
					popup.active = true;
				}else{
					popup.active = false;
				}
			});
			this.$forceUpdate();
		},
		copy: async function(id){
			this.popups.forEach(popup => {
				if(popup.name === 'edit'){
					popup.params = Object.assign(popup.params || {}, {
						id: id,
						name: popup.displayName.replace('ändern', 'kopieren'),
					});
					popup.params.copy = true;
					
					popup.active = true;
				}else{
					popup.active = false;
				}
			});
			this.$forceUpdate();
		},
		showEventDetails: async function(id){
			this.popups.forEach(popup => {
				if(popup.name === 'showDetails'){
					popup.active = true;
					popup.params = Object.assign(popup.params || {}, {
						event: id,
						name: popup.displayName,
					});
				}else{
					popup.active = false;
				}
			});
			this.$forceUpdate();
		},
		
		remove: async function(id){
			if(confirm(this.deleteMsg)){
				const result = await restClient.ajax({
					endpoint: `${this.endpoint}/${id}`,
					method: 'DELETE',
				});
				if(result){
					if(result.error){
						alert(result.error);
					}else{
						this.loadPage();
					}
				}else{
					alert('Fehler');
				}
			}
		},
		startSearch: function(searchFilter = false){
			this.searchFilter = searchFilter;
			
			if(this.searchIntervall){
				clearTimeout(this.searchIntervall);
			}
			this.searchIntervall = setTimeout(() => {
				this.search(searchFilter);
			}, 500)
		},
		search: function(searchFilter = false){
			this.page = 1;
			this.loadPage(searchFilter);
		},
		changePaginationLength: function(evt){
			this.pageSize = parseInt(evt.target.value);
			this.page = 1;
			PaginationStorage.set(this.searchFilterId, this.pageSize);
			
			this.loadPage();
			
		}
	}
};
</script>

<style scoped>

</style>