<template>
	<section>
		<div class="infocard row">
			<label class="info-titel">Freigabesicht</label>
			
			<div class="form-group">
				<AjaxSelect
					endpoint="usergroup"
					v-bind:displayFields="['name']"
					v-bind:initialId="this.mainId"
					ref="mainUserGroup"
				></AjaxSelect>
			</div>
		</div>
		<div class="infocard row">
			<label class="info-titel">Freigabestatus
				<Icon
					name="info"
					title="Bezieht sich nur auf die Hauptgruppe"
				></Icon>
			</label>
			
			<div class="form-group">
				<div class="radioPair">
					<input type="radio" value="1" v-model="releaseState"/>
					<label>ja</label>
				</div>
				<div class="radioPair">
					<input type="radio" value="0" v-model="releaseState"/>
					<label>nein</label>
				</div>
				<div class="radioPair">
					<input type="radio" :value="null" v-model="releaseState"/>
					<label>beides</label>
				</div>
			</div>
		</div>
		
		<div class="infocard">
			<label class="info-titel">Weitere Gruppen</label>
			<NosBtn icon="add" bKind="create-small" v-on:clicked="addUserGroup()"></NosBtn>
			<div v-for="group in subGroups">
				<AjaxSelect
					endpoint="usergroup"
					v-bind:displayFields="['name']"
					v-bind:initialId="group"
					ref="subUserGroups"
				></AjaxSelect>
			</div>
		</div>
		
		<div class="infocard row">
			<label>Von:</label>
			<div class="form-group">
				<input type="date" v-model="date_from"/>
			</div>
			<label>Bis:</label>
			<div class="form-group">
				<input type="date" v-model="date_till"/>
			</div>
		</div>
		
		<div class="infocard row">
			<label class="info-titel">Veranstaltungsort</label>
			
			<div class="form-group">
				<AjaxSelect
					endpoint="location"
					v-bind:displayFields="['name']"
					ref="location"
					:pageSize="0"
				></AjaxSelect>
			</div>
		</div>
		
		<div class="infocard row">
			<label class="info-titel">Veranstaltungsart</label>
			
			<div class="form-group">
				<AjaxSelect
					endpoint="eventkind"
					v-bind:displayFields="['name']"
					ref="eventKind"
				></AjaxSelect>
			</div>
		</div>
		
		<NosBtn icon="save" title="übernehmen" bKind="save"
			v-on:clicked="$emit('startSearch'); $emit('close');"
		></NosBtn>
	</section>
</template>

<script>
export default {
	name: "EventReleaseSearchPopup",
	data: function(){
		return {
			mainId: null,
			releaseState: null,
			subGroups: [],
			date_from: null,
			date_till: null,
		};
	},
	created: function(){
		this.mainId = sessionStorage.lraUserGroupId;
	},
	methods: {
		addUserGroup: function(){
			this.subGroups.push(null);
		},
		createFilter: function(){
			const result = {
				groups: [{
					name: this.$refs.mainUserGroup.display,
					id: this.$refs.mainUserGroup.selectedId,
				}],
				filter: [],
			};
			
			if(this.$refs.subUserGroups){
				this.$refs.subUserGroups.forEach(subGroup => {
					if(subGroup.selectedId){
						result.groups.push({
							name: subGroup.display,
							id: subGroup.selectedId,
						});
					}
				});
			}
			
			if(this.date_from){
				result.filter.push(['event_dates.date', '>=', this.date_from]);
			}
			
			if(this.date_till){
				result.filter.push(['event_dates.date_end', '<=', this.date_till]);
			}
			
			if(this.$refs.eventKind && this.$refs.eventKind.selectedId){
				result.filter.push(['events.kind', '=', this.$refs.eventKind.selectedId]);
			}
			
			if(this.$refs.location && this.$refs.location.selectedId){
				result.filter.push(['event_dates.location', '=', this.$refs.location.selectedId]);
			}
			if(this.releaseState !== null){
				if(this.releaseState == 1){
					result.filter.push(['event_releases.released', '=', 1]);
				}else{
					result.filter.push(['event_releases.released', '!=', 1]);
				}
				result.filter.push(['event_releases.user_group', '=', result.groups[0].id]);
			}
			
			
			return result;
		}
		
	},
	
}
</script>

<style scoped>

</style>