<template>
	<section class="flex row center items-center">
		
		<div style="max-width: 800px; width:100%">
			<div class="infocard wrap">
				<label class="info-titel">Übergruppe</label>
				
				<!-- Name -->
				<div class="form-group">
					<label for="userName">Gruppenname
						<Required/>
					</label>
					<AjaxSelect
						endpoint="usergroup"
						v-bind:displayFields="['name']"
						v-bind:initialId="this.id"
						ref="mainUserGroup"
						aria-describedby="userName">
					</AjaxSelect>
					<span class="helpertext"> Wähle bitte eine bestehende Gruppe aus </span>
				</div>
			</div>
			
			<div class="infocard">
				<label class="info-titel">Gruppen</label>
				<NosBtn icon="add" bKind="create-small" v-on:clicked="addUserGroup()"></NosBtn>
				<div v-for="(child,index) in data.children" :key="child.uid"
					style="display:flex; flex-direction: row; margin-bottom: 5px;">
					<div style="width: 80%;">
						<AjaxSelect
							endpoint="usergroup"
							v-bind:displayFields="['name']"
							v-bind:selectedObject="child"
							ref="userGroups">
						</AjaxSelect>
					</div>
					<div style="width:20%;">
						<NosBtn :evt="{index:index}" icon="delete" v-on:clicked="removeGroup($event)"/>
					</div>
					<br/>
				</div>
			</div>
		</div>
		
		<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
	</section>
</template>

<script>
import restClient from '../../rest';
import {generateUniqueId} from '../../helper';

export default {
	name: "EditUserGroupAuthority",
	data: function(){
		return {
			data: {
				id: null,
				name: '',
				children: [],
			},
		};
	},
	props: {
		id: Number,
		copy: {
			kind: Boolean,
			default: false,
		},
	},
	created: async function(){
		if(this.id){
			const res = await restClient.ajax({
				endpoint: `userGroupAuthority/${this.id}`,
				method: 'GET',
			});
			if(res){
				this.data = res;
				this.initialData = res;
				
				if(this.data.children){
					this.data.children.forEach(child => {
						child.uid = generateUniqueId();
					});
				}
			}else{
				//fehlermeldung
			}
			
			if(this.copy){
				this.id = null;
			}
			
		}
	},
	methods: {
		addUserGroup: function(){
			this.data.children.push({
				email: null,
				id: null,
				image: null,
				name: '',
				uid: generateUniqueId(),
			})
		},
		save: async function(){
			const endpoint = `userGroupAuthority${this.id? '/' + this.id : ''}`;
			const method = this.id? 'PUT' : 'POST';
			
			const groups = [];
			
			this.$refs.userGroups.forEach(select => {
				if(select.selectedId){
					groups.push(select.selectedId);
				}
			});
			const result = await restClient.ajax({
				endpoint: endpoint,
				method: method,
				data: {
					selectedAuthority: this.id? this.id : this.$refs.mainUserGroup.selectedId,
					groups: groups,
				},
			});
			
			if(result && result.id){
				this.$emit('refreshTableItem', result);
				this.$emit('closeSuccess');
			}else{
				//Fehlermeldung
			}
		},
		removeGroup: function(evt){
			this.data.children.splice(evt.index, 1);
		},
	},
	
}
</script>

<style scoped>

</style>