<template>
	<section>
		<AjaxTable
			endpoint="apiUser"
			headline="Api Benutzer"
			searchFilterId="apiUser"
			v-bind:displayFields="displayFields"
			v-bind:fieldKinds="[]"
			v-bind:popups="popups"
			v-bind:queryFields="queryFields"
			v-bind:initialHeader="initialHeader"
		></AjaxTable>
	</section>
</template>

<script>
export default {
	name: 'ApiUserIndex',
	data: function(){
		return {
			initialHeader: [
				'Domain',
			],
			queryFields: ['domain'],
			popups: [
				{
					name: 'edit',
					component: 'EditApiUser',
					displayName: 'Api Benutzer ändern',
					params: {}
				},
				{
					name: 'create',
					component: 'EditApiUser',
					displayName: 'Api Benutzer erstellen',
					params: {}
				},
			],
			displayFields: [
				{
					kind: 'field',
					name: 'domain',
				},
			],
		};
	},
};
</script>

<style scoped>

</style>
