<template>
	<section>
		<AjaxTable
			endpoint="lraUser"
			headline="Benutzer"
			searchFilterId="user"
			v-bind:displayFields="displayFields"
			v-bind:fieldKinds="[]"
			v-bind:popups="popups"
			v-bind:queryFields="queryFields"
			v-bind:initialHeader="initialHeader"
		></AjaxTable>
	</section>
</template>

<script>
export default {
	name: "LraUserIndex",
	data: function(){
		return {
			initialHeader: [
				'Name',
				'Email',
				'Gruppe',
				'Domain',
			],
			queryFields: ['name', 'email'],
			popups: [
				{
					name: 'edit',
					component: 'EditLraUser',
					displayName: 'Benutzer ändern',
					params: {
						fullscreen: true,
					},
				},
				{
					name: 'create',
					component: 'EditLraUser',
					displayName: 'Benutzer erstellen',
					params: {
						fullscreen: true,
					},
				},
			],
			displayFields: [
				{
					kind: 'field',
					name: 'name',
				},
				{
					kind: 'field',
					name: 'email',
				},
				{
					kind: 'field',
					name: 'group',
				},
				{
					kind: 'field',
					name: 'domain',
				},
			],
		};
	},
}
</script>

<style scoped>

</style>