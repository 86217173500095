<template>
	<i v-bind:style="iconStyle" v-bind:title="title" class="material-icons">{{ name }}</i>
</template>

<script>
export default {
	name: "Icon",
	props: {
		name: String,
		iconStyle: {
			type: Object,
			default: () => new Object,
		},
		title: {
			type: String,
			default: '',
		},
	},
}
</script>

<style scoped>

</style>