<template>
	<section class="flex column center items-center">
		
		<div class="infocard" style="max-width: 800px;min-width:600px;">
			
			<label class="info-titel"> Info </label>
			
			<div class="form-group">
				<MediaSelect
					type="row"
					:showDelete="false"
					v-bind:image="data.image"
					mediaGroup="UserGroup"
					ref="userGroupMedia"
					styleId="media-select-popup-event"
				></MediaSelect>
				<span class="helpertext"> Das Bild, repräentiert die Gruppe </span>
			</div>
			
			<!-- Gruppenname -->
			<div class="form-group">
				<label for="userGroup"> Gruppenname
					<Required/>
				</label>
				<input id="userGroup" type="text" v-model.trim="data.name" aria-describedby="Gruppenname">
				<span class="helpertext"> Bezeichnung der Gruppe  </span>
			</div>
			
			<!-- Gruppenname -->
			<div class="form-group">
				<label for="email">Email
					<Required/>
				</label>
				<input id="email" type="email" v-model.trim="data.email" aria-describedby="email">
				<span class="helpertext"> Die Hauptemail für diese Gruppe </span>
			</div>
		
		</div>
		
		<div class="infocard" style="max-width: 800px;min-width:600px;">
			<label class="info-titel"> Durch Gruppengruppierung enthaltene Orte </label>
			
			<div class="form-group">
				<div v-for="child in locations.children" :key="child.id">
					{{ child.name }}
				</div>
			</div>
		</div>
		<div class="infocard" style="max-width: 800px;min-width:600px;">
			<div class="form-group">
				<label class="info-titel">Eigene Orte</label>
				<NosBtn icon="add" bKind="create-small" v-on:clicked="addLocation()"></NosBtn>
				<div v-for="(child, index) in locations.own " :key="child.uid"
					style="display:flex; flex-direction: row; margin-bottom: 5px;">
					<div style="width: 80%;">
						<AjaxSelect
							endpoint="location"
							v-bind:displayFields="['name']"
							v-bind:selectedObject="child"
							v-bind:pageSize="0"
							ref="locations"
						></AjaxSelect>
					</div>
					<div style="width:20%;">
						<NosBtn :evt="{index:index}" icon="delete" v-on:clicked="removeLocation($event)"/>
					</div>
					<br/>
				</div>
			</div>
		</div>
		
		<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
	</section>
</template>

<script>
import restClient from '../../rest';
import {generateUniqueId} from '../../helper';

const apiEndpoint = 'usergroup';

export default {
	name: "EditUsergroup",
	data: function(){
		return {
			data: {
				id: null,
				name: '',
				email: '',
				image: {url: '', id: ''},
			},
			locations: {
				own: [],
				children: [],
			},
		};
	},
	props: {
		id: Number,
		copy: {
			kind: Boolean,
			default: false,
		},
	},
	created: async function(){
		if(this.id){
			this.loadLocations();
			const res = await restClient.ajax({
				endpoint: `${apiEndpoint}/${this.id}`,
				method: 'GET',
			});
			if(res){
				this.data = res;
				this.initialData = res;
			}else{
				//fehlermeldung
			}
			
			if(this.copy){
				this.data.id = null;
			}
			
		}else{
		
		}
	},
	methods: {
		loadLocations: async function(){
			const res = await restClient.ajax({
				endpoint: `groupLocations`,
				method: 'GET',
				data: {
					userGroup: this.id,
				},
			});
			
			if(res){
				this.locations = res;
				this.locations.own.forEach(loc => {
					loc.uid = generateUniqueId();
				});
			}
		},
		addLocation: function(){
			this.locations.own.push({id: '', uid: generateUniqueId()});
		},
		removeLocation: function(evt){
			this.locations.own.splice(evt.index, 1);
		},
		save: async function(){
			const endpoint = `${apiEndpoint}${this.data.id? '/' + this.data.id : ''}`;
			const method = this.data.id? 'PUT' : 'POST';
			
			const MediaSelect = this.$refs.userGroupMedia;
			let body;
			switch(MediaSelect.kind){
				case 'api':
					body = {
						name: this.data.name,
						image: MediaSelect.id,
						email: this.data.email,
					}
					break;
				
				case 'upload':
					body = new FormData();
					body.append('name', this.data.name);
					body.append('email', this.data.email);
					body.append('image', MediaSelect.uploadedFile);
					break;
				case null:
					body = {
						name: this.data.name,
						image: null,
						email: this.data.email,
					}
			}
			
			let locations = [];
			if(this.$refs.locations){
				this.$refs.locations.forEach(loc => {
					if(loc.selectedId){
						locations.push(loc.selectedId);
					}
				});
			}
			//array_unique
			locations = locations.filter((value, index, self) => self.indexOf(value) === index);
			
			if(body instanceof FormData){
				body.append('locations', JSON.stringify(locations));
			}else{
				body.locations = locations;
			}
			const result = await restClient.ajax({
				endpoint: endpoint,
				method: method,
				data: body,
			});
			
			if(result && result.id){
				this.$emit('refreshTableItem', result);
				this.$emit('closeSuccess');
			}else{
				if(result && result.errors){
					alert(result.errors.join("\n"));
				}
			}
		},
	},
}
</script>

<style scoped>

</style>