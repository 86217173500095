<template>
	<section>
		<AjaxTable
			endpoint="location"
			headline="Orte"
			searchFilterId="locations"
			v-bind:displayFields="displayFields"
			v-bind:fieldKinds="[{name:'gps',kind:'gps'},]"
			v-bind:popups="popups"
			v-bind:queryFields="queryFields"
			v-bind:queryParams="queryParams"
			v-bind:initialHeader="initialHeader"
		></AjaxTable>
	</section>
</template>

<script>
export default {
	name: 'LocationIndex',
	data: function(){
		return {
			queryParams: {
				withoutChildren: 1,
			},
			initialHeader: [
				'Name',
			],
			queryFields: ['name'],
			popups: [
				{
					name: 'edit',
					component: 'EditLocation',
					displayName: 'Orte ändern',
					params: {
						fullscreen: true,
					},
				},
				{
					name: 'create',
					component: 'EditLocation',
					displayName: 'Orte erstellen',
					params: {
						fullscreen: true,
					},
				},
			],
			displayFields: [
				{
					kind: 'field',
					name: 'name'
				},
			],
		};
	},
};
</script>

<style scoped>

</style>