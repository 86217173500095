<template>
	<div>
		<button
			v-if="icon && title"
			class="NosBtn"
			type="button"
			v-bind:class="buttonKind"
			v-bind:title="help"
			v-bind:aria-label="help"
			v-on:click="clicked()"
		>
			<i class="material-icons" style="margin-right: 1rem;"> {{ icon }} </i>
			{{ title }}
		</button>
		
		<button
			v-else-if="icon && !title"
			class="NosBtn"
			type="button"
			v-bind:class="buttonKind"
			v-bind:title="help"
			v-bind:aria-label="help"
			v-on:click="clicked()"
		>
			<i class="material-icons"> {{ icon }} </i>
		</button>
		
		<button
			v-if="!icon && title"
			class="NosBtn"
			type="button"
			v-bind:class="buttonKind"
			v-bind:title="help"
			v-bind:aria-label="help"
			v-on:click="clicked()"
		>
			{{ title }}
		</button>
	</div>
</template>


<script>


export default {
	name: "NosBtn",
	data: function(){
		return {};
	},
	props: {
		title: null,
		icon: null,
		bKind: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		evt: {
			type: Object,
			default: () => {
				return {};
			},
		},
		help: {
			type: String,
			default: '',
		},
	},
	computed: {
		/**
		 * prüft welcher bkind der Componente übergeben wurde
		 * und legt so die Art des Buttons fest, indem zur Laufzeit durch
		 * v-bind:class="buttonKind die jeweilige CSS Klasse hinzugefügt wird.
		 */
		buttonKind: function(){
			let btn = {};
			switch(this.bKind){
				case 'primary':
					btn.primary = true;
					break;
				case 'secondary':
					btn.secondary = true;
					break;
				
				case 'edit':
					btn.edit = true;
					break;
				
				case 'danger':
					btn.danger = true;
					break;
				
				case 'create':
					btn.create = true;
					break;
				
				
				case 'create-small':
					btn.create_small = true;
					break;
				
				
				case 'save':
					btn.save = true;
					break;
				
				case 'close':
					btn.close = true;
					break;
				
				case 'cancel':
					btn.cancel = true;
					break;
				
				default:
					btn.normal = true;
				
			}
			
			btn.disabled = this.disabled;
			return btn;
		},
	},
	methods: {
		clicked: function(){
			if(!this.disabled){
				this.$emit('clicked', this.evt);
			}
		}
	},
}
</script>

<style scoped>

</style>