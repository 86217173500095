<template>
	<div id="SuccessPopup">
		<h3 class="success">
			Erfolgreich
		</h3>
	</div>
</template>

<script>
	export default {
		name: "SuccessPopup",
		created(){
			setTimeout(() => {
				this.$emit('close');
			}, 750);
		}
	}
</script>