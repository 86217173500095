<template>
	<section class="formBorder">
		
		<div class="infocard">
			<label class="info-titel" for="apiUserGroup">Gruppe</label>
			<!-- Gemeinde -->
			<div class="form-group">
				<label for="eventTitel"> Gruppename
					<Required/>
					: </label>
				<AjaxSelect
					inputId="apiUserGroup"
					endpoint="usergroup"
					v-bind:displayFields="['name']"
					v-bind:initialId="this.data.group_id"
					ref="usergroup">
				</AjaxSelect>
				<span class="helpertext"> Bitte eine bestehende Gruppe auswählen </span>
			</div>
		</div>
		
		<div class="infocard">
			<label class="info-titel">Kalender Webseite</label>
			<div class="form-group">
				<label for="apiUserDomain"> Domain
					<Required/>
					: </label>
				<input id="apiUserDomain" type="text" v-model.trim="data.domain" maxlength="191">
				<span class="helpertext"> URL der Webseite, auf dem der Kalender angezeigt werden soll </span>
			</div>
		</div>
		
		<div class="infocard">
			<label class="info-titel">Api Schlüssel:&nbsp;<span v-on:click="copyApiKey($event)"
				title="Api Key kopieren"><Icon
				v-bind:iconStyle="{'font-size': '16px', cursor: 'pointer'}"
				name="content_copy"></Icon></span>
			</label>
			<div class="form-group">
				<input id="apiUserKey" type="text" disabled placeholder="Api Key (wird generiert)"
					v-model.trim="data.key"/>
				<span class="helpertext"> Der API Schlüssel wird beim speichern automatisch generiert </span>
			</div>
		</div>
		
		<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
	</section>
</template>

<script>
import restClient from '../../rest';

export default {
	name: "EditLraApiUser",
	data: function(){
		return {
			data: {
				id: null,
				domain: '',
				key: '',
			},
		};
	},
	props: {
		id: Number,
		copy: {
			kind: Boolean,
			default: false,
		},
	},
	created: async function(){
		this.loadData();
	},
	methods: {
		loadData: async function(copy = false){
			if(!copy && this.id){
				this.data.id = this.id;
			}
			if(this.data.id){
				const res = await restClient.ajax({
					endpoint: `lraApiUser/${this.data.id}`,
					method: 'GET',
				});
				if(res){
					this.data = res;
					this.initialData = res;
				}else{
					//fehlermeldung
				}
				
				if(this.copy){
					this.data.id = null;
					if(!copy){
						this.data.key = '';
					}
				}
				
			}else{
			
			}
		},
		copyApiKey: function(evt){
			evt.target.style.color = '#28a745';
			setTimeout(() => {
				evt.target.style.color = 'inherit';
			}, 500);
			const el = document.createElement('textarea');
			el.value = this.data.key;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
		},
		save: async function(){
			const endpoint = `lraApiUser${this.data.id? '/' + this.data.id : ''}`;
			const method = this.data.id? 'PUT' : 'POST';
			
			const result = await restClient.ajax({
				endpoint: endpoint,
				method: method,
				data: {
					domain: this.data.domain,
					key: this.data.key,
					group_id: this.$refs.usergroup.selectedId,
				},
			});
			
			if(result && result.id){
				
				this.$emit('refreshTableItem', result);
				if(this.data.id){
					this.$emit('closeSuccess');
				}else{
					this.data.id = result.id;
					this.loadData(true);
				}
			}else{
				if(result && result.errors){
					alert(result.errors.join("\n"));
				}
			}
		},
	},
}
</script>

<style scoped>

</style>