<template>
	<section class="formBorder">
		
		<div class="infocard">
			<label for="inputort" class="info-titel"> Ortsname
				<Required/>
			</label>
			<!-- LängenOrtsnamegrad -->
			<div class="form-group">
				<input id="inputort" type="text" v-model.trim="data.name" placeholder="Name" maxlength="191"/>
				<span class="helpertext"> möglicher Hilfe- oder Beispieltext </span>
			</div>
		</div>
		
		<div class="infocard">
			<label class="info-titel">Ort gehört zu (optional)</label>
			<AjaxSelect
				endpoint="location"
				v-bind:displayFields="['name']"
				v-bind:initialId="this.data.parent"
				ref="parentLocation"
				:pageSize="0"
			></AjaxSelect>
		</div>
		<div class="infocard">
			<label class="info-titel"> GPS: </label>
			<!-- Breitengrad -->
			<div class="form-group">
				<label for="eventTitel">Breitengrad:</label>
				<input type="text" v-model.trim="data.gps.lat" placeholder="Breitengrad"/>
				<span class="helpertext"> möglicher Hilfe- oder Beispieltext </span>
			</div>
			<!-- Längengrad -->
			<div class="form-group">
				<label for="eventTitel">Längengrad:</label>
				<input type="text" v-model.trim="data.gps.lng" placeholder="Längengrad"/>
				<span class="helpertext"> möglicher Hilfe- oder Beispieltext </span>
			</div>
		</div>
		
		<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
	
	</section>
</template>

<script>
const restClient = window.CalendarApp.client;

export default {
	name: "EditLocation",
	data: function(){
		return {
			data: {
				name: '',
				gps: {
					lat: null,
					lng: null,
				},
				parent: null,
			},
			initData: null,
		}
	},
	props: {
		id: Number,
		copy: {
			kind: Boolean,
			default: false,
		}
	},
	created: async function(){
		if(this.id){
			const res = await restClient.ajax({
				endpoint: `location/${this.id}`,
				method: 'GET',
			});
			if(res){
				this.data = res;
				
				const pos = this.data.name.indexOf('(')
				if(pos > -1){
					this.data.name = this.data.name.substr(0, pos - 1);
				}
				
				
				this.initialData = res;
			}else{
				//fehlermeldung
			}
			
			if(this.copy){
				this.id = null;
			}
			
		}
	},
	
	methods: {
		save: async function(){
			const endpoint = `location${this.id? '/' + this.id : ''}`;
			const method = this.id? 'PUT' : 'POST';
			
			const result = await restClient.ajax({
				endpoint: endpoint,
				method: method,
				data: {
					name: this.data.name,
					gps: this.data.gps,
					parent: this.$refs.parentLocation.selectedId,
				},
			});
			if(result && result.id){
				this.$emit('refreshTableItem', result);
				this.$emit('closeSuccess');
			}else{
				if(result && result.errors){
					alert(result.errors.join("\n"));
				}
			}
		},
	},
}
</script>

<style scoped>

</style>