<template>
	<section>
		<AjaxTable
			endpoint="lraApiUser"
			headline="Api Benutzer"
			searchFilterId="apiUser"
			v-bind:displayFields="displayFields"
			v-bind:fieldKinds="[]"
			v-bind:popups="popups"
			v-bind:queryFields="queryFields"
			v-bind:initialHeader="initialHeader"
		></AjaxTable>
	</section>
</template>

<script>
export default {
	name: 'LraApiUserIndex',
	data: function(){
		return {
			initialHeader: [
				'Gruppe',
				'Domain',
			],
			queryFields: ['name', 'domain'],
			popups: [
				{
					name: 'edit',
					component: 'EditLraApiUser',
					displayName: 'Api Benutzer ändern',
					params: {}
				},
				{
					name: 'create',
					component: 'EditLraApiUser',
					displayName: 'Api Benutzer erstellen',
					params: {}
				},
			],
			displayFields: [
				{
					kind: 'field',
					name: 'group_name',
				},
				{
					kind: 'field',
					name: 'domain',
				},
			],
		};
	},
};
</script>

<style scoped>

</style>
