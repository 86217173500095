<template>
	<div>
		<AjaxTable
			endpoint="myRecommendations"
			headline="Empfehlungen"
			searchFilterId="myRecommendations"
			v-bind:displayFields="displayFields"
			v-bind:fieldKinds="fieldKinds"
			v-bind:popups="popups"
			v-bind:queryFields="queryFields"
			v-bind:defaultFilter="defaultSearchFilter"
			v-bind:initialHeader="initialHeader"
			v-bind:disableButtons="true"
			v-bind:queryParams="queryParams"
			idField="uId"
		>
			<template v-slot:markSeen="slotProps">
				<MarkSeen
					:seen="slotProps.content.seen"
					:event="slotProps.content.id"
					parentCallback="loadPage"
				></MarkSeen>
			</template>
			
			<template v-slot:eventDetails="slotProps">
				<EventDetailButton :event="slotProps.content.id"></EventDetailButton>
			</template>
			
			<template v-slot:releaseCheckbox="slotProps">
				<AjaxCheckbox
					:initialRecord="slotProps.content.id"
					:initialValue="slotProps.content.released"
					:params="{userGroup: sessionStorage.lraUserGroupId}"
					valueName="released"
					endpoint="toggleRelease"
					help="Freigabe"
				></AjaxCheckbox>
			</template>
			
			<template v-slot:searchComponent="slotProps">
				<MyRecommendationsSearch
					fieldName="name"
					v-bind:searchFilter="slotProps.searchFilter"
					v-bind:searchFilterId="slotProps.searchFilterId"
				></MyRecommendationsSearch>
			</template>
		</AjaxTable>
	
	</div>

</template>

<script>
import {getCurrentDate} from '../../helper';

export default {
	data: function(){
		return {
			queryParams: {
				withSeen: 1,
			},
			queryFields: ['name', 'event_dates.title'],
			defaultSearchFilter: [
				['recommendations.seen', '=', 0],
				['event_dates.date', '>=', getCurrentDate()],
			],
			initialHeader: [
				'Datum',
				'Veranstaltung',
				'Veranstaltungsdetails',
				'Ersteller',
				'Freigabe',
				'Geprüft',
			],
			popups: [
				{
					name: 'edit',
					component: 'EditEvent',
					displayName: 'Veranstaltung ändern',
					params: {
						fullscreen: true,
					}
				},
				{
					name: 'create',
					component: 'EditEvent',
					displayName: 'Veranstaltung erstellen',
					params: {
						fullscreen: true,
					}
				},
				{
					name: 'recommendation',
					component: 'SuggestionPopup',
					displayName: 'Veranstaltung empfehlen',
					params: {
						fullscreen: true,
					}
				},
			],
			displayFields: [
				{
					kind: 'field',
					name: 'date',
				},
				{
					kind: 'field',
					name: 'name',
				},
				{
					name: 'eventDetails',
					kind: 'slot',
					slotName: 'eventDetails',
				},
				{
					kind: 'field',
					name: 'created_by_name',
				},
				{
					name: 'releases',
					kind: 'slot',
					slotName: 'releaseCheckbox',
				},
				{
					name: 'markSeen',
					kind: 'slot',
					slotName: 'markSeen',
				},
			],
			fieldKinds: [
				{
					name: 'date',
					kind: 'date',
				},
			],
		};
	},
	computed: {
		sessionStorage: () => sessionStorage,
	},
};
</script>

<style scoped>

</style>
