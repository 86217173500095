<template>
	<div id="EditEvent" class="flexbox">
		
		<div class="form-container">
			
			<div class="form-item-row">
				<!-- Allgemeine Infos -->
				<div class="form-item infocard row space-between">
					<label class="info-titel"> Allgemeine Infos </label>
					
					<!-- Titel -->
					<div class="form-group" style="width:100%">
						<label for="eventTitel">Titel
							<Required />
							:</label>
						<input type="text" id="eventTitel" v-model.trim="data.name" maxlength="191"
							aria-describedby="eventTitel" />
						<span class="helpertext"> z.B. Einweihungsfeier </span>
					</div>
					
					
					<!-- Art -->
					<div class="form-group">
						<label for="eventKind">Veranstaltungsart
							<Required />
							:</label>
						<AjaxSelect
							endpoint="eventkind"
							v-bind:displayFields="['name']"
							v-bind:initialId="this.data.kind.id"
							inputId="eventKind"
							ref="eventKind">
						</AjaxSelect>
						<span class="helpertext"> z.B. Musik & Tanz </span>
					</div>
					
					<!-- Anmeldung? -->
					<div class="column center" style="width: unset !important;">
						<label for="eventRegistration" class="row center">Anmeldung erforderlich?</label>
						<div class="form-group row center">
							<label id="eventRegistration" class="switch" style="top: 3px">
								<input type="checkbox" v-model="data.registration" value="1">
								<span class="slider round"></span>
							</label>
						</div>
					</div>
					
					<!-- Eigenschaften -->
					<div class="form-group w-100">
						<label>Eigenschaften:</label>
						<span class="helpertext">Wähle spezielle Eigenschaften dieser Veranstaltung </span>
						
						<div class="row space-between">
							<div
								class="column items-center clickable"
								v-on:click="data.accessible = data.accessible ? 0 : 1"
								title="Barrierefreie Veranstaltung"
							>
								<img v-bind:src="accessibilityIcon" />
								Barrierefrei
							</div>
							<div
								class="column items-center clickable"
								v-on:click="data.sustainability = data.sustainability ? 0 : 1"
								title="Nachhaltige Veranstaltung"
							>
								<img v-bind:src="ecoIcon" />
								Nachhaltig
							</div>
							<div
								class="column items-center clickable"
								v-on:click="data.family_friendly = data.family_friendly ? 0 : 1"
								title="Kinder- und familienfreundliche Veranstaltung"
							>
								<img v-bind:src="familyIcon" />
								Familienfreundlich
							</div>
							<div
								class="column items-center clickable"
								v-on:click="data.with_cost = data.with_cost ? 0 : 1"
							
							>
								<Icon
									name="euro_symbol"
									style="font-size: 94px;"
									v-bind:style="data.with_cost? 'color: #28a745' : 'color: #000'"
									title="Kostenpflichtige Veranstaltung"
								>
								</Icon>
								Kostenpflichtig
							</div>
						</div>
					</div>
					
					<!-- Preisbeschreibung -->
					<div class="form-group w-100">
						<label for="price">Preis Beschreibung:</label>
						<!-- <textarea type="text" id="Preis" v-model.trim="data.price" /> -->
						<ckeditor
							:editor="editor.kind"
							v-model.trim="data.price"
							:config="editor.config"
							aria-describedby="Preis €"
							:readonly="!data.with_cost"
							:disabled="!data.with_cost"
							style="height: 100px"
							id="price"
						></ckeditor>
						<span class="helpertext"> ACHTUNG: Ist nur pflegbar, wenn die Eigenschaft Kostenpflichtig gesetzt ist! </span>
					</div>
					
					<div class="form-group w-100">
						<label for="presell">Link Ticketshop:</label>
						<input
							id="presell"
							type="text"
							v-model="data.ticket_presale"
							:disabled="!data.with_cost"
						/>
						<span class="helpertext">Link Ticketshop: (URL, z.B. https://example.com) - ACHTUNG: Ist nur pflegbar, wenn die Eigenschaft Kostenpflichtig gesetzt ist! </span>
					</div>
				</div>
				
				
				<!-- Veranstalter -->
				<div class="form-item infocard row space-between">
					<label class="info-titel"> Veranstalter </label>
					<!-- Name -->
					<div class="form-group" style="width: 100%;">
						<label for="organiser">Name
							<Required />
							:</label>
						<input
							type="text"
							class="form-control"
							id="organiser"
							v-model.trim="data.organisation"
							maxlength="191"
							aria-describedby="organiser"
							required
						>
						<span class="helpertext"> z.B. Nosgroup GmbH </span>
					</div>
					<!-- Webseite -->
					<div class="form-group">
						<label for="website">Webseite:</label>
						<input id="website" class="form-control" type="text" v-model.trim="data.web" maxlength="191" />
						<span class="helpertext"> z.B. www.nosgroup.de </span>
					</div>
					<!-- Email -->
					<div class="form-group">
						<label for="email">Email:
							<Required title="Entweder Email oder Telefon müssen ausgefüllt sein" />
						</label>
						<input id="email" class="form-control" type="email" v-model.trim="data.mail" maxlength="191" />
						<span class="helpertext"> z.B. info@nosgroup.de </span>
					</div>
					
					<div class="row w-100">
						<!-- Vorwahl -->
						<div class="form-group" style="width: 50px">
							<label for="phoneArea">Vorwahl:</label>
							<input id="phoneArea" class="form-control" type="text" v-model.trim="phoneArea"
								maxlength="10" />
							<span class="helpertext"> z.B. 09521 </span>
						</div>
						<!-- Nummer -->
						<div class="form-group">
							<label for="phoneNum">Telefon:
								<Required title="Entweder Email oder Telefon müssen ausgefüllt sein" />
							</label>
							<input id="phoneNum" class="form-control" type="text" v-model.trim="phoneNum"
								maxlength="180" />
							<span class="helpertext"> z.B. 952824 </span>
						</div>
					</div>
				
				</div>
			</div>
			
			<div class="form-item infocard column">
				<!-- <label class="info-titel"> Empfehlen</label> -->
				<label class="info-titel"> Soziale Medien </label>
				<div class="column center" style="width: unset !important;">
					<label for="facebook" class="row"> Facebook </label>
					<div class="form-group row center">
						<input id="facebook" type="text" v-model.trim="data.facebook" placeholder="Facebook Url"
							maxlength="255" />
					</div>
				</div>
				<div class="column center" style="width: unset !important;">
					<label for="twitter" class="row"> Twitter </label>
					<div class="form-group row">
						<input id="twitter" type="text" v-model.trim="data.twitter" placeholder="Twitter Url"
							maxlength="255" />
					</div>
				</div>
				<div class="column center" style="width: unset !important;">
					<label for="instagram" class="row"> Instagram </label>
					<div class="form-group row center">
						<input id="instagram" type="text" v-model.trim="data.instagram" placeholder="Instagram Url"
							maxlength="255" />
					</div>
				</div>
			</div>
			
			<!-- Event Dates -->
			<div class="form-item infocard row space-between" style="width: 100% !important;">
				<label class="info-titel"> Veranstaltungsdatum </label>
				<NosBtn icon="add" bKind="create-small" v-on:clicked="addEventDate()" help="Datum hinzufügen"></NosBtn>
				<div class="row wrap" style="width: 100%;">
					<template v-for="(date, index) in data.eventDates">
						<EventDate
							:key="date.id"
							v-bind:parentTitle="data.name"
							v-bind:index="index"
							v-bind:date="date"
							v-on:removeEventDate="removeEventDate($event)"
							ref="eventDates"
							v-on:locationSelected="setLastLocation($event)"
						></EventDate>
					</template>
				</div>
				<template v-if="data.eventDates.length > 0">
					<div style="position: relative; width: 100%; height: 50px;">
						<NosBtn icon="add" bKind="create-small" v-on:clicked="addEventDate()"
							help="Datum hinzufügen"></NosBtn>
					</div>
				</template>
			</div>
			<!-- Medien -->
			<div class="form-item infocard row space-between" style="width: 100% !important;">
				<label class="info-titel">Medien:</label>
				<NosBtn style="" icon="add" bKind="create-small" v-on:clicked="addMedia()"
					help="Bild hinzufügen"></NosBtn>
				
				<div class="w-100 column">
					<template v-for="(file,index) in data.media">
						<MediaSelect
							v-bind:loopid="index"
							v-bind:type="'row'"
							:key="file.uid ? file.uid : file.id"
							v-bind:image="file"
							ref="eventMedia"
							mediaGroup="event"
							v-on:removemedia="removeMedia($event)"
						></MediaSelect>
					</template>
				</div>
			</div>
			
			<div class="form-item infocard row space-between" style="width: 100% !important;">
				<label class="info-titel">
					<span>Freigaben</span>
					<span v-if="releaseDisabled" style="font-size:small">Kann ohne Datum nicht freigegeben werden</span>
				</label>
				<template v-for="group in releaseInfo">
					<div class="column center" style="width: 30% !important; margin-bottom: 20px;"
						v-bind:class="{checkboxDisabled: releaseDisabled}">
						<label v-bind:for="`release_${group.id}`" class="row center" style="margin-bottom:0">
							{{ group.name }} </label>
						<div class="row center">
							<label class="switch" style="top: 3px">
								<input v-bind:id="`release_${group.id}`" type="checkbox" v-model="group.released"
									value="1" v-bind:disabled="releaseDisabled">
								<span class="slider round"></span>
							</label>
						</div>
					</div>
				</template>
			</div>
		</div>
		
		
		<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
	</div>
</template>

<script>

import restClient from '../../rest';
import {generateUniqueId} from '../../helper';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

export default {
	name: "EditEvent",
	
	// Attributes
	data: function(){
		return {
			attributes: [],
			icon_size: '/2x/',
			icon_url: 'http://events.hassberge.de/storage/icons',
			editor: {
				kind: InlineEditor,
				config: {
					language: 'de',
					toolbar: {
						items: [
							'bold',
							'italic',
							//'underline',
							'|',
							'link',
							'bulletedList',
							'numberedList',
							'|',
							'undo',
							'redo',
						],
					}
				},
			},
			lastSelectedLocation: null,
			data: {
				kind: {
					id: '',
				},
				eventDates: [],
				media: [],
				facebook: '',
				twitter: '',
				instagram: '',
				accessible: 0,
				sustainability: 0,
				family_friendly: 0,
				with_cost: 0,
				organisation: '',
				ticket_presale: '',
			},
			phoneArea: '',
			phoneNum: '',
			recommend: 1,
			releases: {
				mainGroup: null,
				authGroups: [],
				releases: {},
			},
			initialData: null,
		};
	},
	
	// initial Atributes
	props: {
		id: Number,
		copy: {
			kind: Boolean,
			default: false,
		},
	},
	computed: {
		releaseDisabled: function(){
			return this.data.eventDates.length === 0;
		},
		accessibilityIcon: function(){
			if(this.data.accessible){
				return this.icon_url + '/accessible_black' + this.icon_size + 'icon_green.png';
			}else{
				return this.icon_url + '/accessible_black' + this.icon_size + 'icon.png';
			}
		},
		ecoIcon: function(){
			if(this.data.sustainability){
				return this.icon_url + '/eco_black' + this.icon_size + 'icon_green.png';
			}else{
				return this.icon_url + '/eco_black' + this.icon_size + 'icon.png';
			}
		},
		familyIcon: function(){
			if(this.data.family_friendly){
				return this.icon_url + '/family_restroom' + this.icon_size + 'icon_green.png';
			}else{
				return this.icon_url + '/family_restroom' + this.icon_size + 'icon.png';
			}
		},
		Areacode: function(){
			return this.getPhoneNumberArray()[0];
		},
		
		Phonenumber: function(){
			return this.getPhoneNumberArray()[1];
		},
		
		releaseInfo: function(){
			const releases = [];
			if(this.releases.mainGroup){
				releases.push({
					id: this.releases.mainGroup.id,
					name: this.releases.mainGroup.name,
					released: this.releases.releases[this.releases.mainGroup.id],
				});
				this.releases.authGroups.forEach(group => {
					releases.push({
						id: group.id,
						name: group.name,
						released: this.releases.releases[group.id],
					});
				});
			}
			return releases;
		},
	},

// Constructor
	created: async function(){
		const releaseData = {};
		
		if(this.id){
			releaseData.event = this.id;
		}
		
		this.releases = await restClient.ajax({
			endpoint: `eventReleases`,
			method: 'GET',
			data: releaseData
		});
		if(this.id){
			const res = await restClient.ajax({
				endpoint: `event/${this.id}`,
				method: 'GET',
			});
			if(res){
				this.data = res;
				if(res.phone){
					const phoneParts = res.phone.split(' ');
					this.phoneArea = phoneParts[0] || '';
					this.phoneNum = phoneParts[1] || '';
				}
				this.data.media.forEach((file) => {
					file.uid = this.createUid();
				});
				this.initialData = res;
			}else{
				//fehlermeldung
			}
			
			if(this.copy){
				this.id = null;
				
				this.data.eventDates.forEach(eventDate => {
					eventDate.id = this.generateRandomId();
					eventDate.event = '';
				})
			}
			
			
		}else{
		
		}
		
	},

// Methods
	methods: {
		createUid: generateUniqueId,
		addEventDate: function(){
			let location = {id: '',};
			
			if(this.lastSelectedLocation){
				location = Object.assign({}, this.lastSelectedLocation);
			}
			
			this.data.eventDates.push({
				id: this.generateRandomId(),
				location: location,
				gps: {
					lat: '',
					lng: '',
				},
				title: '',
				description: '',
				street: '',
				street_nr: '',
				adress_supplement: '',
				date: '',
				date_end: '',
				start: '',
				end: '',
				whole_day: '',
			});
		},
		
		generateRandomId: function(){
			let cont = false;
			let id = '';
			do{
				cont = false;
				id = 'A' + generateUniqueId();
				for(let ev of this.data.eventDates){
					if(ev.id == id){
						cont = true;
						break;
					}
				}
			}while(cont);
			
			return id;
		},
		
		removeEventDate: function(index){
			this.data.eventDates.splice(index, 1);
		},
		
		save: async function(){
			let id = this.id? this.id.toString() : null;
			if(id && id.substr(0, 1) === 'A'){
				id = null;
			}
			const method = id? 'PUT' : 'POST';
			
			const body = Object.assign({}, this.data);
			
			if(!body.organisation){
				alert('Veranstalter ist ein Pflichtfeld');
				return;
			}
			
			
			body.phone = `${this.phoneArea.replace(' ', '')} ${this.phoneNum.replace(' ', '')}`;
			
			if(!body.mail && !body.phone.trim()){
				alert('Es wird eine Kontaktmöglichkeit für den Veranstalter benötigt');
				return;
			}
			
			body.kind = this.$refs.eventKind.selectedId;
			body.eventDates = [];
			
			let dateError = false;
			if(this.$refs.eventDates){
				this.$refs.eventDates.forEach(eventDate => {
					const obj = Object.assign({}, eventDate.eventDate);
					obj.location = eventDate.$refs.location.selectedId;
					
					if(!this.validateTime(obj)){
						dateError = true;
					}
					
					if(obj.whole_day){
						obj.start = '';
						obj.end = '';
					}
					
					body.eventDates.push(obj);
				});
			}
			if(dateError){
				alert('Datum oder Zeit passen nicht zussammen');
				return;
			}
			body.releases = this.releaseInfo;
			
			const result = await restClient.ajax({
				endpoint: `event${id? '/' + id : ''}`,
				method: method,
				data: body,
			});
			if(result && result.id){
				await this.updateMedia(result.id);
				if(result.id){
					this.$emit('refreshTableItem', result);
				}
				if(this.recommend){
					this.$emit('openPopUp', {name: 'recommendation', id: result.id});
				}else{
					this.$emit('closeSuccess');
				}
			}else{
				if(result && result.errors){
					alert(result.errors.join("\n"));
				}
			}
		},
		validateTime: function(eventDate){
			if(eventDate.date_end){
				if(Date.parse(eventDate.date) > Date.parse(eventDate.date_end)){
					return false;
				}
			}
			
			if(eventDate.start && eventDate.end){
				return parseInt(eventDate.start.split(':').join('')) < parseInt(eventDate.end.split(':').join(''))
			}
			
			return true;
		},
		updateMedia: async function(eventId){
			const form = new FormData();
			const media = this.getMedia();
			let useForm = false;
			media.forEach(m => {
				useForm = true;
				switch(m.kind){
					case 'api':
						form.append('apiMedia[]', m.id);
						break;
					
					case 'upload':
						form.append('uploadedMedia[]', m.uploadedFile);
						break;
				}
			});
			return restClient.ajax({
				endpoint: `eventMedia/${eventId}`,
				method: 'PUT',
				data: useForm? form : {},
			});
		},
		
		getMedia: function(){
			const media = [];
			let refs = [];
			if(Array.isArray(this.$refs.eventMedia)){
				refs = this.$refs.eventMedia;
			}else if(this.$refs.eventMedia){
				refs = [this.$refs.eventMedia];
			}
			
			refs.forEach(file => {
				if(file.kind){
					media.push(file);
				}
			});
			
			return media;
		},
		
		addMedia: function(){
			this.data.media.push({uid: this.createUid()});
		},
		
		removeMedia: function(index){
			this.data.media.splice(index, 1);
		},
		
		getPhoneNumberArray: function(){
			if(this.data.phone){
				return this.data.phone.split(" ");
			}else{
				return ['', ''];
			}
		},
		setLastLocation: function(evt){
			this.lastSelectedLocation = {
				id: evt.id,
				name: evt.name,
			};
		},
		
	},
}
</script>

<style scoped lang="scss">

</style>