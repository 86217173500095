<template>
	<span v-bind:title="plainText">{{ shortenedText }}</span>
</template>

<script>

import {stripTags} from '../../html';

export default {
	name: "ShortenedText",
	data: function(){
		return {};
	},
	props: {
		text: {
			type: String,
			default: '',
		},
		length: {
			type: Number,
			default: 20,
		},
	},
	computed: {
		shortenedText: function(){
			const text = stripTags(this.text);
			if(text.length <= this.length){
				return text;
			}
			
			return text.substr(0, this.length - 3) + '...';
		},
		plainText: function(){
			return stripTags(this.text);
		},
	},
}
</script>


<style scoped>

</style>