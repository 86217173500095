<template>
	<div id="lraCalendarUser" class="row">
		<div class="doku-download">
			<a
				href="https://events.hassberge.de/lra/calendar.zip"
				download
			>
				<Icon name="cloud_download" title="Dokumentation und Anpassungen"/>
			</a>
		</div>
		<div>
			<div><b>Gemeinde:</b> {{ groupName }}</div>
			<div><b>Name:</b> {{ name }}</div>
		</div>
		<NosBtn icon="power_settings_new" bKind="danger" v-on:clicked="logout()" help="Logout"></NosBtn>
	</div>
</template>

<script>
export default {
	name: "UserMenu",
	data: function(){
		return {
			name: sessionStorage.lraUserName,
			groupName: sessionStorage.lraUserGroupName,
			open: false,
		};
	},
	methods: {
		logout: function(){
			let sessionKeys = ['lastMainView', 'lraUserName', 'lraUserEmail', 'lraUserGroupName', 'lraUserGroupId', 'lraToken',]
			sessionKeys.forEach(name => sessionStorage.removeItem(name));
			window.location.reload();
		}
	},
}
</script>

<style scoped>

</style>