<template>
	<nav id="MainNav">
		<template v-for="entry in entries">
			
			<div class="tab"
				v-if="entry.id != selected"
				v-bind:key="entry.name"
				v-on:click="tabclicked(entry)">
				{{ entry.name }}
			</div>
			
			<div class="tab active"
				v-else v-bind:key="entry.name"
				v-on:click="tabclicked(entry)">
				{{ entry.name }}
			</div>
		
		</template>
	</nav>
</template>

<script>
import restClient from '../../rest';

export default {
	name: 'MainNav',
	data: function(){
		return {
			entries: [],
			selected: '',
		};
	},
	props: {
		selectedComponent: {
			type: String,
			default: '',
		},
	},
	created: async function(){
		this.entries = await restClient.ajax({
			endpoint: 'menu',
			
			method: 'GET',
		});
		
		if(this.entries.length){
			let setFromSession = false;
			if(sessionStorage.lastMainView){
				for(let entry of this.entries){
					if(entry.component === sessionStorage.lastMainView){
						setFromSession = true;
						this.selected = entry.id;
						break;
					}
				}
			}
			if(!setFromSession){
				this.selected = this.entries[0].id;
			}
		}
		
		
	},
	methods: {
		tabclicked: function(entry){
			this.selected = entry.id;
			return this.$emit('entry-clicked', entry.component)
		},
	}
	
};

</script>

<style scoped lang="scss">

</style>