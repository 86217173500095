<template>
	<section>
		<AjaxTable
			endpoint="region"
			headline="Regionen"
			searchFilterId="region"
			v-bind:displayFields="displayFields"
			v-bind:fieldKinds="[]"
			v-bind:popups="popups"
			v-bind:queryFields="queryFields"
		></AjaxTable>
	</section>
</template>

<script>
export default {
	data: function(){
		return {
			queryFields: ['name'],
			popups: [
				{
					name: 'edit',
					component: 'EditRegion',
					displayName: 'Region ändern',
				},
				{
					name: 'create',
					component: 'EditRegion',
					displayName: 'Region erstellen',
				},
			],
			displayFields: [
				{
					kind: 'field',
					name: 'name',
				},
			],
		};
	},
};
</script>

<style scoped>

</style>