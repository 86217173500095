<template>
	<div id="spinner" v-bind:class="visibility">
		<span class="material-icons">
			motion_photos_on
		</span>
	</div>
</template>

<script>
export default {
	name: "Spinner",
	data: function(){
		return {
			active: false,
		};
	},
	computed: {
		visibility: function(){
			return {
				visible: this.active,
				hidden: !this.active,
			};
		},
	},
	created: function(){
		document.addEventListener('ajaxStarted', this.activate);
		document.addEventListener('ajaxEnded', this.deactivate);
	},
	destroyed: function(){
		document.removeEventListener('ajaxStarted', this.activate);
		document.removeEventListener('ajaxEnded', this.deactivate);
	},
	
	methods: {
		activate: function(){
			this.active = true;
		},
		deactivate: function(){
			this.active = false;
		},
	},
}
</script>

<style scoped>

</style>