<template>
	<section>
		<AjaxTable
			endpoint="userGroupAuthority"
			headline="Gruppengruppierungen"
			searchFilterId="userGroupAuthority"
			v-bind:displayFields="displayFields"
			v-bind:fieldKinds="fieldkinds"
			v-bind:popups="popups"
			v-bind:queryFields="queryFields"
			v-bind:initialHeader="initialHeader"
		>
			<template v-slot:children="slotProps">
				<ListText :list="slotProps" fieldName="name"></ListText>
			</template>
		</AjaxTable>
	</section>
</template>

<script>
export default {
	name: "UserGroupAuthorityIndex",
	data: function(){
		
		return {
			initialHeader: [
				'',
				'Hauptgruppe',
				'Untergruppen'
			],
			queryFields: [],
			popups: [
				{
					name: 'edit',
					component: 'EditUserGroupAuthority',
					displayName: 'Gruppe ändern',
					params: {
						fullscreen: true,
					},
				},
				{
					name: 'create',
					component: 'EditUserGroupAuthority',
					displayName: 'Gruppe erstellen',
					params: {
						fullscreen: true,
					},
				},
			],
			displayFields: [
				{
					kind: 'field',
					name: 'image.url',
					width: 15,
				},
				{
					kind: 'field',
					name: 'name',
				},
				{
					kind: 'slot',
					name: 'children',
				},
			],
			fieldkinds: [
				{
					name: 'url',
					kind: 'img'
				},
			],
		};
	},
}
</script>

<style scoped>

</style>