<template>
	<section class="flex row center items-center">
		<div class="">
			<div class="infocard row wrap" style="max-width: 800px;">
				<label class="info-titel"> Benutzer Info</label>
				
				<!-- Gruppenzugehörigkeit -->
				<div class="form-group w-100" style="margin-bottom: 1rem;">
					<label for="userGroup"> Gruppenzugehörigkeit
						<Required/>
						: </label>
					<AjaxSelect
						inputId="userGroup"
						endpoint="usergroup"
						v-bind:displayFields="['name']"
						v-bind:initialId="this.data.group_id"
						ref="usergroup">
					</AjaxSelect>
					<span class="helpertext"> Zu welcher Gruppe soll der Benutzer Zugeordnet werden? </span>
				</div>
				<!-- Name -->
				<div class="form-group">
					<label for="userName">Name
						<Required/>
						:</label>
					<input id="userName" type="text" v-model.trim="data.name" aria-describedby="userName"
						maxlength="191"/>
				</div>
				
				<!-- Email -->
				<div class="form-group">
					<label for="userEmail"> Email
						<Required/>
						: </label>
					<input id="userEmail" type="email" v-model.trim="data.email" aria-describedby="Email"
						maxlength="191"/>
				</div>
				
				<!-- Passwort -->
				<div class="form-group">
					<label for="userPassword"> Passwort
						<template v-if="!id">
							<Required/>
						</template>
						: </label>
					<input id="userPassword" type="text" v-model.trim="data.password" aria-describedby="Email"/>
				</div>
				
				<!-- Backend URL: -->
				<div class="form-group w-100">
					<label for="userDomain"> Backend URL
						<Required/>
						: </label>
					<input id="userDomain" type="text" v-model.trim="data.domain" aria-describedby="Backend URL:"/>
				</div>
			
			</div>
		</div>
		
		
		<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
	</section>
</template>

<script>
import restClient from '../../rest';

export default {
	name: "EditLraUser",
	data: function(){
		return {
			data: {
				id: '',
				name: '',
				email: '',
				password: '',
				group_id: '',
				domain: window.location.hostname,
			}
		};
	},
	props: {
		id: Number,
		copy: {
			kind: Boolean,
			default: false,
		},
	},
	created: async function(){
		if(this.id){
			const res = await restClient.ajax({
				endpoint: `lraUser/${this.id}`,
				method: 'GET',
			});
			if(res){
				this.data = res;
				this.initialData = res;
			}else{
				//fehlermeldung
			}
			
			if(this.copy){
				this.data.id = null;
			}
			
		}else{
			this.group = 1;
		}
	},
	methods: {
		save: async function(){
			const endpoint = `lraUser${this.data.id? '/' + this.data.id : ''}`;
			const method = this.data.id? 'PUT' : 'POST';
			const result = await restClient.ajax({
				endpoint: endpoint,
				method: method,
				data: {
					name: this.data.name,
					email: this.data.email,
					password: this.data.password,
					domain: this.data.domain,
					group_id: this.$refs.usergroup.selectedId,
				},
			});
			
			if(result && result.id){
				this.$emit('refreshTableItem', result);
				this.$emit('closeSuccess');
			}else{
				if(result && result.errors){
					alert(result.errors.join("\n"));
				}
			}
		},
	},
}
</script>

<style scoped>

</style>