<template>
	<section>
		<AjaxTable
			endpoint="usergroup"
			headline="Gruppen"
			searchFilterId="usergroup"
			v-bind:displayFields="displayFields"
			v-bind:fieldKinds="fieldkinds"
			v-bind:popups="popups"
			v-bind:queryFields="queryFields"
			v-bind:queryParams="queryParams"
			v-bind:initialHeader="initialHeader"
		>
			
			<template v-slot:userGroups="slotProps">
				<ListText :list="slotProps" fieldName="userGroups"></ListText>
			</template>
		</AjaxTable>
	</section>
</template>

<script>
export default {
	name: "UsergroupIndex",
	data: function(){
		
		return {
			queryParams: {
				withGroups: 1,
			},
			initialHeader: [
				'Bild',
				'Name',
				'Email',
				'Gruppengruppierungen',
			],
			queryFields: ['name', 'email',],
			popups: [
				{
					name: 'edit',
					component: 'EditUsergroup',
					displayName: 'Gruppe ändern',
					params: {
						fullscreen: true,
					},
				},
				{
					name: 'create',
					component: 'EditUsergroup',
					displayName: 'Gruppe erstellen',
					params: {
						fullscreen: true,
					},
				},
			],
			displayFields: [
				{
					kind: 'field',
					name: 'image.url',
					width: 15,
				},
				{
					kind: 'field',
					name: 'name',
				},
				{
					kind: 'field',
					name: 'email',
				},
				{
					kind: 'slot',
					name: 'userGroups',
				},
			],
			fieldkinds: [
				{
					name: 'url',
					kind: 'img'
				},
			],
		};
	},
}
</script>

<style scoped>

</style>