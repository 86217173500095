<template>
	<div class="flex row center items-center">
		<div class="infocard">
			
			<!-- Benutzername -->
			<div class="form-group">
				<label for="username">Benutzername
					<Required/>
				</label>
				<input id="username" type="text" v-model.trim="data.name" aria-describedby="Benutzername"
					maxlength="191">
				<span class="helpertext"> Name des Benutzers</span>
			</div>
			
			<!-- Email -->
			<div class="form-group">
				<label for="email">Email
					<Required/>
				</label>
				<input id="email" type="email" v-model.trim="data.email" aria-describedby="Email" maxlength="191">
				<span class="helpertext"> Login des Benutzers </span>
			</div>
			
			<!-- Passwort -->
			<div class="form-group">
				<label for="password"> Passwort
					<Required/>
				</label>
				<input id="password" type="text" v-model.trim="data.password" aria-describedby="Passwort">
				<span class="helpertext"> Passwort des Benutzers </span>
			</div>
			
			<!-- Domain -->
			<div class="form-group">
				<label for="domain">Domain</label>
				<input id="domain" type="text" v-model.trim="data.domain" aria-describedby="Domain">
				<span class="helpertext"> Domain des Benutzers </span>
			</div>
		
		</div>
		
		<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
	
	</div>
</template>

<script>
import restClient from '../../rest';

export default {
	name: "EditUser",
	data: function(){
		return {
			data: {
				id: '',
				name: '',
				email: '',
				password: '',
				domain: window.location.hostname,
			},
		};
	},
	props: {
		id: Number,
		copy: {
			kind: Boolean,
			default: false,
		},
	},
	created: async function(){
		if(this.id){
			const res = await restClient.ajax({
				endpoint: `user/${this.id}`,
				method: 'GET',
			});
			if(res){
				this.data = res;
				this.initialData = res;
			}else{
				//fehlermeldung
			}
			
			if(this.copy){
				this.data.id = null;
			}
			
		}else{
		
		}
	},
	methods: {
		save: async function(){
			const endpoint = `user${this.data.id? '/' + this.data.id : ''}`;
			const method = this.data.id? 'PUT' : 'POST';
			
			const result = await restClient.ajax({
				endpoint: endpoint,
				method: method,
				data: {
					name: this.data.name,
					email: this.data.email,
					password: this.data.password,
					domain: this.data.domain,
				},
			});
			
			if(result && result.id){
				this.$emit('refreshTableItem', result);
				this.$emit('closeSuccess');
			}else{
				if(result && result.errors){
					alert(result.errors.join("\n"));
				}
			}
		},
	},
}
</script>

<style scoped>

</style>