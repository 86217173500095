<template>
	<section class="MediaSelectPopup">
		<div class="mediaSelectSearchBlock">
			<input type="text" v-model.trim="searchword" placeholder="Nach Dateiname suchen"
				v-on:keyup="startSearch()"/>
		</div>
		<div class="image-auswahl" style="display: flex; width:100%; flex-wrap: wrap; flex-direction: row;">
			<div class="image-container" v-for="image in images" :key="image.id"
				v-on:click="$emit('imageSelected', image);">
				<img v-bind:src="image.url" v-bind:title="image.name"/>
				<ShortenedText :text="image.name" :length="20"></ShortenedText>
			</div>
		</div>
		<div class="row">
			<div class="img-pagination">
				<NosBtn icon="navigate_before" bKind="secondary" v-on:clicked="prevPage()"
					v-bind:disabled="!prev"></NosBtn>
				<p v-if="perPage"> Seite: {{ page }} von {{ lastPage }} </p>
				<select class="form-control" style="width:65px !important" v-model="pageSize"
					v-on:change="changePaginationLength($event)">
					<option
						v-for="i in Array.from({length:100}).map((v,j) => j+1).filter((v) => v%5 === 0)"
						:value="i"
						:key="i"
					>{{ i }}
					</option>
				</select>
				<NosBtn icon="navigate_next" bKind="secondary" v-on:clicked="nextPage()"
					v-bind:disabled="!next"></NosBtn>
			</div>
			<!-- speichern Btn -->
			<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
		</div>
	
	
	</section>
</template>

<script>
import restClient from '../../rest';
import {PaginationStorage} from '../storage';

const PaginationId = 'MediaSelectPagination';

export default {
	name: "MediaSelectPopup",
	data: function(){
		return {
			searchword: '',
			searchTimeout: null,
			lastSearchWord: '',
			images: [],
			page: 1,
			prev: null,
			next: null,
			perPage: 0,
			lastPage: 0,
			pageSize: 30,
		};
	},
	props: {
		mediaGroup: String,
	},
	created: async function(){
		this.pageSize = PaginationStorage.get(PaginationId, 30);
		await this.loadPage();
	},
	methods: {
		loadPage: async function(){
			if(this.searchword !== this.lastSearchWord){
				this.page = 1;
			}
			this.lastSearchWord = this.searchword;
			
			const body = {
				group: this.mediaGroup,
				page: this.page,
				pageSize: this.pageSize,
			};
			
			if(this.searchword){
				body.filter = [
					['file_name', 'like', this.searchword],
				];
			}
			
			const result = await restClient.ajax({
				endpoint: 'media',
				data: body,
			});
			
			if(result){
				this.page = result.meta.current_page;
				this.prev = result.links.prev? result.meta.current_page - 1 : null;
				this.next = result.links.next? result.meta.current_page + 1 : null;
				this.perPage = result.meta.per_page;
				this.lastPage = result.meta.last_page;
				
				this.images = result.data;
			}else{
				//TODO: errorhandling
			}
		},
		startSearch: function(){
			if(this.searchTimeout){
				clearTimeout(this.searchTimeout);
			}
			
			this.searchTimeout = setTimeout(() => {
				this.loadPage();
			}, 500);
		},
		nextPage: async function(){
			this.page++;
			this.loadPage();
		},
		prevPage: async function(){
			this.page--;
			this.loadPage();
		},
		changePaginationLength: function(evt){
			this.pageSize = parseInt(evt.target.value);
			this.page = 1;
			PaginationStorage.set(PaginationId, this.pageSize);
			this.loadPage();
		},
	}
}
</script>

<style scoped lang="scss">

</style>