<template>
	<div>
		<div v-if="creator.name">
			<h3>Hochgeladen von:</h3>
			<p>{{ creator.name }}</p>
		</div>
		</hr>
		<div v-if="events.length">
			<h3>Wird in {{ events.length }} Veranstaltung<span v-if="events.length !== 1">en</span> verwendet</h3>
			<div style="width:75%; padding: 0;">
				<div style="display:flex; flex-direction:row; border-bottom:1px solid #000;">
					<div style="width:80%;padding: 0;">
						Name
					</div>
					<div style="width: 20%;">
						Datum
					</div>
				</div>
				<div
					v-for="event in events"
					:key="event.id"
					style="display:flex; flex-direction:row; border-bottom:1px solid #000; padding:0"
				>
					<div style="width:80%;">
						{{ event.name }}
					</div>
					<div style="width: 20%;">
						<div
							v-if="event.eventDates.length"
							v-for="eventDate in event.eventDates"
							:key="eventDate.date"
							style="margin: 0; padding: 0;"
						>
							{{ (new Date(eventDate.date)).toLocaleDateString() }}
						</div>
						<span v-else>
							Kein Datum
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import restClient from '../../rest';

export default {
	name: "MediaUsage",
	data(){
		return {
			events: [],
			creator: {},
		};
	},
	props: {
		id: {
			type: Number,
		},
	},
	async created(){
		const creator = await restClient.ajax({
			endpoint: `mediaCreator/${this.id}`,
			method: 'GET',
		});
		
		if(creator){
			this.creator = creator.data;
		}
		
		const res = await restClient.ajax({
			endpoint: `mediaUsage/${this.id}`,
			method: 'GET',
		});
		if(res){
			this.events = res;
		}
	},
}
</script>

<style scoped>

</style>