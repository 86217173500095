<template>
	<AjaxTable
		endpoint="myEvents"
		headline="Veranstaltungen"
		searchFilterId="myEvents"
		v-bind:displayFields="displayFields"
		v-bind:fieldKinds="fieldKinds"
		v-bind:popups="popups"
		v-bind:queryFields="queryFields"
		v-bind:initialHeader="initialHeader"
		idField="uId"
	>
		<template v-slot:releaseCheckbox="slotProps">
			<AjaxCheckbox
				:initialRecord="slotProps.content.id"
				:initialValue="slotProps.content.released"
				:params="{userGroup: sessionStorage.lraUserGroupId}"
				valueName="released"
				endpoint="toggleRelease"
				help="Freigabe"
			>
			</AjaxCheckbox>
		</template>
		
		<template v-slot:searchComponent="slotProps">
			<MyEventsSearch
				fieldName="name"
				v-bind:searchFilter="slotProps.searchFilter"
				v-bind:searchFilterId="slotProps.searchFilterId"
			></MyEventsSearch>
		</template>
		
		<template v-slot:share="slotProps">
			<NosBtn
				icon="share"
				kind="secondary"
				v-on:clicked="downloadICS(slotProps)"
				help="Download Kalendereintrag"
			/>
		</template>
	</AjaxTable>
</template>

<script>
import {stripTags} from '../../html';
import {downloadIcsFile} from '../../ics';

export default {
	data: function(){
		return {
			queryFields: ['name', 'event_dates.title'],
			initialHeader: [
				'Datum',
				'Veranstaltung',
				'Ort',
				'Ersteller',
				'Freigabe',
				'Teilen',
			],
			popups: [
				{
					name: 'edit',
					component: 'EditEvent',
					displayName: 'Veranstaltung ändern',
					params: {
						fullscreen: true,
					}
				},
				{
					name: 'create',
					component: 'EditEvent',
					displayName: 'Veranstaltung erstellen',
					params: {
						fullscreen: true,
					}
				},
				{
					name: 'recommendation',
					component: 'SuggestionPopup',
					displayName: 'Veranstaltung empfehlen',
					params: {
						fullscreen: true,
					}
				},
				{
					name: 'showDetails',
					component: 'EventDetail',
					displayName: 'Veranstaltung',
					params: {
						fullscreen: true,
					}
				},
			],
			displayFields: [
				{
					kind: 'field',
					name: 'date',
				},
				{
					kind: 'field',
					name: 'name',
					width: 40,
				},
				{
					kind: 'field',
					name: 'eventDates.0.location.name',
				},
				{
					kind: 'field',
					name: 'created_by_media',
					width: 10,
				},
				{
					name: 'releases',
					kind: 'slot',
					slotName: 'releaseCheckbox',
				},
				
				{
					name: 'share',
					kind: 'slot',
					slotName: 'share',
				},
			],
			fieldKinds: [
				{
					name: 'date',
					kind: 'date',
				},
				{
					name: 'created_by_media',
					kind: 'img',
				},
			],
		};
	},
	computed: {
		sessionStorage: () => sessionStorage,
		alert: () => alert,
		window: () => window,
	},
	methods: {
		downloadICS(props){
			if(props.content.eventDates.length === 0){
				alert('Veranstaltung hat kein Datum definiert');
				return;
			}
			const event = props.content;
			const eventDate = event.eventDates[0];
			
			const timezoneOffset = Math.abs((new Date()).getTimezoneOffset() / 60)
			
			let start = eventDate.date;
			if(eventDate.start){
				start += 'T';
				
				const parts = eventDate.start.split(':');
				//parts[0] = parseInt(parts[0]) + timezoneOffset;
				if(parts[0].length === 1){
					parts[0] = `0${parts[0]}`;
				}
				start += parts.join(':');
			}else{
				start += 'T00:00:00';
			}
			
			let location = eventDate.location.name;
			
			if(eventDate.location.street){
				location += ' ' + eventDate.location.street;
			}
			
			const data = {
				start: new Date(start),
				title: this.title,
				description: eventDate.description? stripTags(eventDate.description) : '',
				location: location,
			};
			
			if(eventDate.date_end){
				let end = eventDate.date_end;
				if(eventDate.end){
					end += 'T';
					
					const parts = eventDate.end.split(':');
					parts[0] = parseInt(parts[0]) + timezoneOffset;
					if(parts[0].length === 1){
						parts[0] = `0${parts[0]}`;
					}
					
					end += parts.join(':');
				}else{
					end += 'T23:59:59';
				}
				data.end = new Date(end);
			}else{
				if(eventDate.end){
					data.end = new Date(`${eventDate.date}T${eventDate.end}`);
				}else{
					data.end = new Date(`${eventDate.date}T23:59:59`);
				}
			}
			downloadIcsFile(data);
			
		},
	}
};
</script>

<style scoped>

</style>