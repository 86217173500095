<template>
	<span class="required" :title="title">*</span>
</template>

<script>
export default {
	name: "Required",
	props: {
		title: {
			type: String,
			default: 'Pflichtfeld',
		},
	},
	
}
</script>

<style scoped>

</style>