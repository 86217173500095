<template>
	<span>
		<template v-if="!seen">
			<span
				class="mark-seen"
				v-on:click="setSeen();"
			>
				<Icon name="check_box_outline_blank" title="Als geprüft setzen"></Icon>
			</span>
		</template>
		<template v-else>
			<span
				class="mark-unseen"
				v-on:click="setUnseen();"
			>
				<Icon name="check_box" title="Als ungeprüft setzen"></Icon>
			</span>
		</template>
	</span>
</template>

<script>
import restClient from '../../rest';

export default {
	name: "MarkSeen",
	props: {
		seen: {
			type: Number,
			default: 0,
		},
		event: {
			type: Number,
			default: 0,
		},
		parentCallback: {
			type: String,
			default: '',
		},
	},
	methods: {
		setSeen: async function(){
			const result = await restClient.ajax({
				endpoint: 'recommendationSeen',
				method: 'POST',
				data: {
					event: this.event,
				},
			});
			
			if(result){
				if(this.parentCallback && this.$parent[this.parentCallback]){
					this.$parent[this.parentCallback]();
				}
			}
		},
		setUnseen: async function(){
			const result = await restClient.ajax({
				endpoint: 'recommendationUnseen',
				method: 'POST',
				data: {
					event: this.event,
				},
			});
			
			if(result){
				if(this.parentCallback && this.$parent[this.parentCallback]){
					this.$parent[this.parentCallback]();
				}
			}
		}
	},
}
</script>

<style scoped>

</style>