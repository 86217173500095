<template>
	<div class="coloumn">
		
		<!-- Search Row -->
		<div class="row space-between">
			<NosBtn
				icon="file_upload"
				bKind="normal"
				help="XLS Upload"
				v-on:clicked="showXLSUploadPopup()"
			>
			</NosBtn>
			<NosBtn
				icon="cloud_download"
				bKind="normal"
				help="Als XML herunterladen"
				v-on:clicked="showDownloadPopup()"
			>
			</NosBtn>
			<input
				style="width:382px;"
				v-model="searchText"
				v-on:keyup="delayedSearch()"
				placeholder="Veranstaltungen durchsuchen"
			/>
			
			<NosBtn
				icon="filter_alt"
				bKind="secondary"
				v-on:clicked="active = !active"
				help="Suchfilter einblenden"
			></NosBtn>
			
			<NosBtn
				icon="clear"
				bKind="secondary"
				v-on:clicked="clearSearchFilter()"
				help="Suchfilter zurücksetzen"
			>
			</NosBtn>
		
		</div>
		
		<template v-if="paramCache">
			<span class="searchBreadcrumb" style="display:block;">
				{{ paramCache.filterText }}
			</span>
		</template>
		
		<div v-if="active" class="ajaxTable-popup">
			<Popup
				component="MyEventsSearchPopup"
				:params="popupParams"
				v-on:close="onClose"
				v-on:startSearch="startSearch()"
				ref="searchPopup"
			></Popup>
		</div>
		
		<div v-if="downloadPopup" class="ajaxTable-popup">
			<Popup
				component="DownloadPopup"
				:params="downloadParams"
				v-on:close="downloadPopup = false"
				ref="downloadPopup"
			></Popup>
		</div>
		<div v-if="xlsPopup" class="ajaxTable-popup">
			<Popup
				component="XlsUploadPopup"
				:params="{fullscreen: true,name: 'Excel Upload',}"
				v-on:close="xlsPopup = false"
				ref="xlsPopup"
			></Popup>
		</div>
	</div>
</template>

<script>

import {SearchFilterStorage} from '../storage';

export default {
	name: "MyEventsSearch",
	data: function(){
		return {
			active: false,
			popupParams: {
				name: 'Filter',
				fullscreen: true,
				searchCache: {},
			},
			searchText: '',
			searchTimeout: null,
			paramCache: null,
			downloadPopup: false,
			downloadParams: null,
			xlsPopup: false,
		};
	},
	props: {
		searchFilter: {
			default: null,
		},
		searchFilterId: {
			default: null,
		},
	},
	created: function(){
		if(this.searchFilterId){
			this.paramCache = {
				filterText: SearchFilterStorage.get(this.searchFilterId + 'text', ''),
			};
		}
		
		if(this.searchFilter){
			this.searchFilter.forEach(p => {
				if(Array.isArray(p) && p.length === 3 && p[0] === 'events.name'){
					this.searchText = p[2];
				}
			});
		}
	},
	methods: {
		onClose: function(){
			this.active = false;
		},
		getFilter: function(){
			let result;
			if(this.$refs.searchPopup){
				result = this.$refs.searchPopup.$refs.innerComponent.createFilter();
				this.paramCache = JSON.parse(JSON.stringify(result));
				this.popupParams.searchCache = this.paramCache;
			}else{
				if(this.paramCache){
					result = JSON.parse(JSON.stringify(this.paramCache));
				}else{
					result = {
						groups: [],
						filter: [],
					};
				}
			}
			if(this.paramCache && this.paramCache.filterText && this.searchFilterId){
				SearchFilterStorage.set(this.searchFilterId + 'text', this.paramCache.filterText);
			}
			if(this.searchText){
				result.filter = result.filter || [];
				result.filter.push(['events.name', 'like', this.searchText]);
			}
			
			return result;
		},
		delayedSearch: function(){
			if(this.searchTimeout){
				clearTimeout(this.searchTimeout);
			}
			
			this.searchTimeout = setTimeout(() => {
				this.startSearch();
			}, 500);
			
		},
		startSearch: function(){
			const params = this.getFilter();
			let body = {};
			
			if(params.filter && params.filter.length){
				body.filter = params.filter;
			}
			
			this.$parent.startSearch(body);
		},
		clearSearchFilter: function(){
			this.searchText = '';
			this.paramCache = {
				groups: [],
				filter: [],
			};
			this.popupParams.searchCache = {};
			
			SearchFilterStorage.delete(this.searchFilterId + 'text');
			SearchFilterStorage.delete(this.searchFilterId);
			
			this.startSearch();
		},
		showDownloadPopup: function(){
			this.downloadParams = {
				filter: this.searchFilter,
				filterText: this.paramCache.filterText,
			};
			this.downloadPopup = true;
		},
		showXLSUploadPopup(){
			this.xlsPopup = true;
		},
	},
}
</script>

<style scoped>

</style>