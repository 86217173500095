<template>
	<div class="content">
		<form v-on:submit="login">
			<div class="login">
				<div>
					<h1> Veranstaltungskalender- <br/> Verwaltung des </h1>
					<div>
						<a href="https://www.hassberge.de/topmenu/startseite.html" target="_blank">
							<img class="login-img"
								src="https://www.hassberge.de/fileadmin/templates/2018/public/images/logo.jpg" alt="">
						</a>
					</div>
					
					<div>
						<!-- Email -->
						<div class="form-group">
							<input type="text" placeholder="Benutzername oder Email" v-model.trim="user"
								aria-describedby="Benutzername oder Email">
							<span class="helpertext"></span>
						</div>
						
						<!-- Passwort -->
						<div class="form-group">
							<input type="password" placeholder="Passwort" v-model.trim="password"
								aria-describedby="Passwort">
							<span class="helpertext"> </span>
						</div>
						
						
						<div class="row center">
							<button class="NosBtn primary" type="submit"> Anmelden</button>
						</div>
					</div>
					<div v-if="error" class="error-msg" style="font-size: small;">
						<p>Anmeldung fehlgeschlagen!</p>
						<p style="margin-bottom: 0.50rem;"> Nutzername oder Passwort falsch </p>
					</div>
				</div>
			</div>
		</form>
		<div class="row center small" style="font-size: small;">
			<a href="https://nosgroup.de/" target="_blank">Ein Produkt der NOSGROUP GmbH</a>
		</div>
	</div>
</template>

<script>
export default {
	data: function(){
		return {
			user: '',
			password: '',
			error: false,
		};
	},
	methods: {
		login: async function(event){
			event.preventDefault();
			const result = await window.CalendarApp.client.ajax({
				endpoint: 'user/login',
				method: 'POST',
				data: {
					user: this.user,
					password: this.password,
				}
			});
			if(result){
				window.CalendarApp.setUser(result);
				window.CalendarApp.setMain('Home');
			}else{
				this.error = true;
			}
		},
	}
};
</script>

<style scoped>

</style>