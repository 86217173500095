<template>
	<section>
		<div>
			<div>
				<p>
					Damit das Auslesen richtig funktioniert, benutzen Sie bitte die Vorlage.
				</p>
				<h3>Vorlage für den Import von Veranstaltungsdaten:</h3>
				<a
					href="https://events.hassberge.de/lra/veranstaltungsupload_vorlage.ods"
					download="Vorlage.ods"
				>Download</a>
			</div>
			<div style="display: flex; flex-direction: row; justify-content: flex-start">
				<div style="margin-right: 40px;">
					<h3>Upload von Veranstaltungsdaten:</h3>
					<NosBtn
						icon="file_upload"
						bKind="secondary"
						v-on:clicked="upload"
						help="Upload"
					></NosBtn>
				</div>
				<div v-if="uploaded">
					<h3>Speichern</h3>
					<NosBtn
						icon="save"
						bKind="primary"
						v-on:clicked="save"
						help="Speichern"
					></NosBtn>
				</div>
				<div v-if="success > 0">
					<h3 class="xls-success-msg">Es wurden {{ success }} Veranstaltungen gespeichert</h3>
				</div>
				<div v-if="hasError">
					<h3 class="xls-error-msg">Datei beinhaltet Fehler</h3>
				</div>
			</div>
		</div>
		<div class="xls-table" style="margin-top: 16px;">
			<div>
				<div
					v-for="col in header"
					:key="col"
					style="font-size: 65%;"
				>
					{{ col }}
				</div>
			</div>
			<div
				v-for="row in rows"
				:key="row.id"
				style="font-size: 65%;"
			>
				<div :class="{hasError: row.errors.title.length}">
					{{ row.title }}
					<HoverInfo
						v-if="row.errors.title.length"
						icon="info"
						:helpTextHtml="row.errors.title.join('</br>')"
					/>
				</div>
				<div :class="{hasError: row.errors.eventKind.length}">{{ row.kind }}</div>
				<div :class="{hasError: row.errors.date_start.length}">
					{{ row.date_start }}
					<HoverInfo
						v-if="row.errors.date_start.length"
						icon="info"
						:helpTextHtml="row.errors.date_start.join('</br>')"
					/>
				</div>
				<div :class="{hasError: row.errors.date_end.length}">
					{{ row.date_end }}
					<HoverInfo
						v-if="row.errors.date_end.length"
						icon="info"
						:helpTextHtml="row.errors.date_end.join('</br>')"
					/>
				</div>
				<div>{{ formatTime(row.time_start) }}</div>
				<div>{{ formatTime(row.time_end) }}</div>
				<div>
					<HoverInfo
						v-if="row.description"
						icon="info"
						:helpTextHtml="row.description"
					/>
				</div>
				<div :class="{hasError: row.errors.location.length}">
					{{ row.location }}
					<HoverInfo
						v-if="row.errors.location.length"
						icon="info"
						:helpTextHtml="row.errors.location.join('</br>')"
					/>
				</div>
				<div>{{ parseInt(row.registration)? 'ja' : 'nein' }}</div>
				<div>{{ parseInt(row.accessible)? 'ja' : 'nein' }}</div>
				<div>{{ parseInt(row.sustainability)? 'ja' : 'nein' }}</div>
				<div>{{ parseInt(row.family_friendly)? 'ja' : 'nein' }}</div>
				<div :class="{hasError: row.errors.price.length}">
					{{ parseInt(row.with_cost)? 'ja' : 'nein' }}
					<HoverInfo
						v-if="row.errors.price.length"
						icon="info"
						:helpTextHtml="row.errors.price.join('</br>')"
					/>
				</div>
				<div>{{ row.price }}</div>
				<div :class="{hasError: row.errors.organisation.length}">
					{{ row.organisation }}
					<HoverInfo
						v-if="row.errors.organisation.length"
						icon="info"
						:helpTextHtml="row.errors.organisation.join('</br>')"
					/>
				</div>
				<div>{{ row.web }}</div>
				<div :class="{hasError: row.errors.mail.length}">
					{{ row.mail }}
					<HoverInfo
						v-if="row.errors.mail.length"
						icon="info"
						:helpTextHtml="row.errors.mail.join('</br>')"
					/>
				</div>
				<div>{{ row.phone_prefix }}</div>
				<div :class="{hasError: row.errors.phone.length}">
					{{ row.phone }}
					<HoverInfo
						v-if="row.errors.phone.length"
						icon="info"
						:helpTextHtml="row.errors.phone.join('</br>')"
					/>
				</div>
				<div>{{ row.facebook }}</div>
				<div>{{ row.twitter }}</div>
				<div>{{ row.instagram }}</div>
			</div>
		</div>
	</section>
</template>

<script>
import Reader from '../../excel';
import restClient from '../../rest';
import {formatTime, toSqlDate} from '../../helper';

function flattenDeep(arr1){
	return arr1.reduce((acc, val) => Array.isArray(val)? acc.concat(flattenDeep(val)) : acc.concat(val), []);
}

function hasErrors(row){
	return flattenDeep(Object.values(row.errors)).length;
}


export default {
	name: "XlsUploadPopup",
	data(){
		return {
			rows: [],
			header: [],
			uploaded: false,
			success: 0,
			hasError: false,
		};
	},
	methods: {
		formatTime,
		async upload(){
			let res;
			try{
				res = await Reader.read();
				this.rows = res.data;
				this.header = res.header;
				this.uploaded = true;
				this.success = 0;
				this.hasError = this.rows.filter((row) => hasErrors(row) > 0).length > 0;
				if(this.hasError){
					this.uploaded = false;
				}
			}catch(e){
				alert(e.toString());
			}
		},
		async save(){
			const returns = [];
			let counter = 0;
			for(const row of this.rows){
				if(!hasErrors(row)){
					const data = {
						name: row.title,
						kind: row.eventKind,
						eventDates: [{
							date: toSqlDate(row.date_start),
							date_end: row.date_end? toSqlDate(row.date_end) : null,
							start: row.time_start,
							end: row.time_end,
							location: row.locationId,
							description: row.description,
						}],
						media: [],
						web: row.web,
						facebook: row.facebook,
						twitter: row.twitter,
						instagram: row.instagram,
						accessible: parseInt(row.accessible)? 1 : 0,
						sustainability: parseInt(row.sustainability)? 1 : 0,
						family_friendly: parseInt(row.family_friendly)? 1 : 0,
						with_cost: parseInt(row.with_cost)? 1 : 0,
						registration: parseInt(row.registration)? 1 : 0,
						price: row.price,
						organisation: row.organisation,
						mail: row.mail,
						phone: `${row.phone_prefix.toString().replace(' ', '')} ${row.phone.toString().replace(' ', '')}`,
						releases: [],
					};
					returns.push({
						index: counter,
						response: await restClient.ajax({
							endpoint: `event`,
							method: 'POST',
							data: data,
						}),
					});
					counter++;
				}
			}
			this.uploaded = false;
			
			returns.forEach((res) => {
				if(typeof res.response.errors !== 'undefined' && res.response.errors.length){
					this.rows[res.index].errors.title = res.response.errors;
				}else{
					this.success++;
				}
			});
			
		}
	},
}
</script>

<style scoped>

</style>