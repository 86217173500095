<template>
	<div>
		<div style="display:flex;flex-wrap:nowrap;justify-content: space-between;">
			<input
				type="text"
				placeholder="Veranstaltungen durchsuchen"
				v-model="searchText"
				v-on:keyup="delayedSearch()"
			/>
			<NosBtn icon="filter_alt" bKind="secondary" v-on:clicked="active = !active"></NosBtn>
		</div>
		
		<div v-if="active" class="ajaxTable-popup">
			<Popup
				component="EventReleaseSearchPopup"
				:params="popupParams"
				v-on:close="onClose"
				v-on:startSearch="startSearch()"
				ref="searchPopup"
			></Popup>
		</div>
	</div>
</template>

<script>
export default {
	name: "EventReleaseSearch",
	data: function(){
		return {
			active: false,
			popupParams: {
				name: 'Filter',
				fullscreen: true,
			},
			searchText: '',
			searchTimeout: null,
			paramCache: null,
		};
	},
	methods: {
		onClose: function(){
			this.active = false;
		},
		getFilter: function(){
			let result;
			if(this.$refs.searchPopup){
				result = this.$refs.searchPopup.$refs.innerComponent.createFilter();
				this.paramCache = JSON.parse(JSON.stringify(result));
			}else{
				if(this.paramCache){
					result = JSON.parse(JSON.stringify(this.paramCache));
				}else{
					result = {
						groups: [],
						filter: [],
					};
				}
			}
			
			if(this.searchText){
				result.filter.push(['events.name', 'like', this.searchText]);
			}
			
			return result;
		},
		delayedSearch: function(){
			if(this.searchTimeout){
				clearTimeout(this.searchTimeout);
			}
			
			this.searchTimeout = setTimeout(() => {
				this.startSearch();
			}, 500);
			
		},
		startSearch: function(){
			const params = this.getFilter();
			let body = {};
			if(params.groups && params.groups.length){
				params.groups.forEach((group, index) => {
					group.index = index;
				});
				this.$parent.displayFieldsVar = [
					{
						kind: 'field',
						name: 'name',
					},
					{
						kind: 'field',
						name: 'eventDate',
					}
				];
				this.$parent.header = [
					'Veranstaltung',
					'Datum',
				];
				params.groups.forEach((group, index) => {
					this.$parent.header.push(group.name);
					this.$parent.displayFieldsVar.push({
						name: 'releases',
						kind: 'slot',
						slotName: 'releaseCheckbox',
						index: index,
					});
				});
				body.groups = params.groups.map(group => group.id);
			}
			
			if(params.filter && params.filter.length){
				body.filter = params.filter;
			}
			
			this.$parent.startSearch(body);
		},
		
	},
}
</script>

<style scoped>

</style>