<template>
	<div class="EventDate form-item infocard row">
		<label class="info-titel">
			{{ eventDate.date.split('-').reverse().join('.') }}
			<template v-if="eventDate.date_end">
				- {{ eventDate.date_end.split('-').reverse().join('.') }}
			</template>
		</label>
		<NosBtn
			icon="delete"
			bKind="danger"
			v-on:clicked="remove()"
			style="position:absolute; right:0; top:1px; z-index:30">
		</NosBtn>
		
		
		<div class="form-group column items-center">
			<label v-bind:for="`canceled${uniqueId}`">Abgesagt</label>
			<label class="switch">
				<input v-bind:id="`canceled${uniqueId}`" title="Abgesagt" type="checkbox"
					   v-model.trim="eventDate.canceled" value="1">
				<span class="slider round" title="Abgesagt"></span>
			</label>
		</div>
		
		
		<div class="form-group column items-center">
			<label v-bind:for="`whole_day${uniqueId}`">Ganztags?</label>
			<label class="switch">
				<input v-bind:id="`whole_day${uniqueId}`" title="Ganztags" type="checkbox"
					   v-model.trim="eventDate.whole_day" value="1">
				<span class="slider round" title="Ganztags"></span>
			</label>
		</div>
		
		<div class="form-group" style="width: 100% !important">
			<label v-bind:for="`title${uniqueId}`">Veranstaltungstitel</label>
			<div style="display: flex;">
				<input type="text" placeholder="Veranstaltungstitel" :value="parentTitle" disabled style="width:40%;"/>
				<div style="width:60%;">
					<input v-bind:id="`title${uniqueId}`" type="text" placeholder="Zusatz"
						   v-model.trim="eventDate.title" maxlength="191"/>
					<span class="helpertext"> z.B. Einweihungsfeier </span>
				</div>
			</div>
		
		</div>
		
		<div class="form-group">
			<label v-bind:for="`date${uniqueId}`">Datum Beginn
				<Required/>
				:</label>
			<input v-bind:id="`date${uniqueId}`" type="date" v-model.trim="eventDate.date"/>
		</div>
		
		<div class="form-group">
			<label v-bind:for="`date_end${uniqueId}`">Datum Ende (optional):</label>
			<input v-bind:id="`date_end${uniqueId}`" class="form-control" type="date"
				   v-model.trim="eventDate.date_end"
				   v-on:click="checkAndSetEnd()"
			/>
		</div>
		
		<div class="form-group">
			<label v-bind:for="`time_start${uniqueId}`">Uhrzeit Beginn:</label>
			<input v-bind:id="`time_start${uniqueId}`" class="form-control" type="time" v-model.trim="eventDate.start"
				   :disabled="eventDate.whole_day == 1"/>
		</div>
		
		<div class="form-group">
			<label v-bind:for="`time_end${uniqueId}`">Uhrzeit Ende:</label>
			<input v-bind:id="`time_end${uniqueId}`" class="form-control" type="time" v-model.trim="eventDate.end"
				   :disabled="eventDate.whole_day == 1"/>
		</div>
		
		
		<div class="row wrap w-100">
			<div class="form-group">
				<label>Ort
					<Required/>
					:</label>
				<AjaxSelect
					endpoint="userGroupLocations"
					v-bind:displayFields="['name']"
					v-bind:initialId="eventDate.location ? eventDate.location.id : null"
					:pageSize="0"
					ref="location"
					v-on:itemSelected="eventDate.location = $event"
				></AjaxSelect>
				<span class="helpertext"> Ort auswählen </span>
			</div>
			<div class="row w-100">
				<!-- Straße: -->
				<div class="form-group">
					<label v-bind:for="`street${uniqueId}`">Straße</label>
					<input v-bind:id="`street${uniqueId}`" class="form-control" type="text"
						   v-model.trim="eventDate.street" maxlength="191"/>
					<span class="helpertext"> z.B. Zeiler Straße </span>
				</div>
				<!-- Hausnummer -->
				<div class="form-group" style="flex-grow: 0; min-width:unset; width: 100px">
					<label v-bind:for="`street_num${uniqueId}`">Hausnummer</label>
					<input v-bind:id="`street_num${uniqueId}`" class="form-control text-center" type="text"
						   v-model.trim="eventDate.street_nr" maxlength="191"/>
					<span class="helpertext"> z.B. 61 </span>
				</div>
			</div>
			<!-- Adresszusatz -->
			<div class="form-group">
				<label v-bind:for="`adress_sup${uniqueId}`">Adresszusatz:</label>
				<input v-bind:id="`adress_sup${uniqueId}`" class="form-control" type="text"
					   v-model.trim="eventDate.address_supplement" maxlength="191"/>
				<span class="helpertext"> z.B. 1. Stockwerk Tür A1 </span>
			</div>
		</div>
		<div class="row w-100">
			<div style="display: flex; flex-direction: row; justify-content: space-between; ">
				<div style="margin-right: 25px;">
					<NosBtn title="GPS" bKind="secondary" :disabled="!eventDate.street"
							@clicked="requestGPSData()"></NosBtn>
				</div>
				<div>
					<NosBtn title="GPS testen" bKind="secondary" :disabled="!eventDate.gps.lat || !eventDate.gps.lng"
							@clicked="checkGPSData()"></NosBtn>
				</div>
			</div>
			<div class="form-group">
				<label>Breitengrad:</label>
				<input type="text" v-model="eventDate.gps.lat"/>
				<span class="helpertext">wird nach Klick auf GPS automatisch ausgefüllt</span>
			</div>
			<div class="form-group">
				<label>Längengrad:</label>
				<input type="text" v-model="eventDate.gps.lng"/>
				<span class="helpertext">wird nach Klick auf GPS automatisch ausgefüllt</span>
			</div>
		
		</div>
		
		<!-- Beschreibung -->
		<div class="form-group" style="width: 100%;">
			<label>Beschreibung:</label>
			<ckeditor
				:config="editor.config"
				:editor="editor.kind" v-model="eventDate.description"
				style="height: 250px"
			></ckeditor>
			<span class="helpertext"> z.B. Kurze Beschreibung des Events an diesem Datum </span>
		</div>
	
	
	</div>
</template>

<script>
import {generateUniqueId} from '../../helper';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import restClient from '../../rest';

export default {
	name: "EventDate",
	data: function(){
		return {
			editor: {
				kind: InlineEditor,
				config: {
					language: 'de',
					toolbar: {
						items: [
							'bold',
							'italic',
							'underline',
							'|',
							'link',
							'bulletedList',
							'numberedList',
							'|',
							'undo',
							'redo',
						],
					}
				},
			},
			eventDate: {
				id: '',
				location: {
					id: '',
				},
				gps: {
					lat: '',
					lng: '',
				},
				canceled: 0,
				title: '',
				description: '',
				street: '',
				street_nr: '',
				adress_supplement: '',
				date: '',
				date_end: '',
				start: '',
				end: '',
				whole_day: '',
			}
		};
	},
	props: {
		date: {
			type: Object,
			default: () => {
			},
		},
		index: Number,
		parentTitle: {
			type: String,
			default: '',
		},
		uniqueId: {
			type: String,
			default: '_' + generateUniqueId(),
		},
	},
	created: function(){
		if(this.date){
			this.eventDate = this.date;
		}
	},
	methods: {
		remove: function(){
			this.$emit('removeEventDate', this.index);
		},
		checkAndSetEnd: function(){
			if(this.eventDate.date && !this.eventDate.date_end){
				this.eventDate.date_end = this.eventDate.date;
			}
		},
		async requestGPSData(){
			let location = '';
			if(this.eventDate.location.name){
				location += ' ' + this.eventDate.location.name;
			}
			
			if(this.eventDate.street){
				location += ' ' + this.eventDate.street;
			}
			if(this.eventDate.street_nr){
				location += ' ' + this.eventDate.street_nr;
			}
			
			location = location.trim();
			if(!location){
				alert('Bitte erst die Adressdaten ausfüllen');
				return;
			}
			const data = await restClient.ajax({
				endpoint: `locationGPS`,
				method: 'GET',
				data: {
					searchString: location,
				}
			});
			if(data.lat && data.lng){
				this.eventDate.gps = data;
			}else{
			
			}
		},
		checkGPSData(){
			const link = document.createElement('a');
			link.target = "_blank";
			link.href = `https://maps.google.de/maps?q=${this.eventDate.gps.lat},${this.eventDate.gps.lng}`;
			
			link.click();
		},
	},
};
</script>

<style scoped lang="scss">

</style>