<template>
	<div class="EditEventKind flex row center items-center">
		<!-- info -->
		<div class="infocard" style="max-width: 800px; position:unset; padding-top:0;">
			<br/>
			
			<!-- Bild -->
			<MediaSelect
				type="row"
				:showDelete="false"
				:image="data.image"
				ref="eventKindMedia"
				mediaGroup="EventKind"
			>
			</MediaSelect>
			
			<div class="form-group">
				<label>Fallback Bild:</label>
				<MediaSelect
					type="row"
					:showDelete="false"
					:image="data.fallbackImage"
					ref="eventKindMediaFallback"
					mediaGroup="EventKind"
				>
				</MediaSelect>
			</div>
			
			<div class="form-group">
				<label>Name
					<Required/>
					:</label>
				<!-- <textarea type="text" id="Preis" v-model.trim="data.price" /> -->
				<input type="text" v-model.trim="data.name" placeholder="Name" maxlength="191"/>
				<span class="helpertext"> Bezeichnung der Veranstaltungsart </span>
			</div>
			
			<!-- Beschreibung -->
			<div class="form-group" style="width: 100%;">
				<label for="eventTitel">Beschreibung
					<Required/>
					:</label>
				<ckeditor :editor="editor.kind" v-model.trim="data.description" :config="editor.config"
					style="height: 100px"></ckeditor>
				<span class="helpertext"> Kurze Beschreibung der Veranstaltungsart </span>
			</div>
		
		</div>
		
		<!-- speichern Btn -->
		<NosBtn icon="save" title="speichern" bKind="save" v-on:clicked="save()"></NosBtn>
	
	</div>
</template>

<script>

const restClient = window.CalendarApp.client;

export default {
	data: function(){
		return {
			editor: {
				kind: window.CalendarApp.ckeditor.kind,
				config: window.CalendarApp.ckeditor.config,
			},
			data: {
				name: '',
				description: '',
				image: {
					id: null,
					name: '',
					url: '',
				},
				fallbackImage: {
					id: null,
					name: '',
					url: '',
				},
				canceled: 0,
			},
			initialData: null,
		};
	},
	props: {
		id: Number,
		copy: {
			type: Boolean,
			default: false,
		},
	},
	created: async function(){
		if(this.id){
			const res = await restClient.ajax({
				endpoint: `eventkind/${this.id}`,
				method: 'GET',
			});
			if(res){
				this.data = res;
				this.initialData = res;
			}else{
				//fehlermeldung
			}
			if(this.copy){
				this.id = null;
			}
		}
	},
	
	methods: {
		save: async function(){
			const MediaSelect = this.$refs.eventKindMedia;
			const FallbackMedia = this.$refs.eventKindMediaFallback;
			
			let mediaKind = 'api';
			if(MediaSelect.kind === 'upload' || FallbackMedia.kind === 'upload'){
				mediaKind = 'upload';
			}
			
			let body;
			switch(mediaKind){
				case 'api':
					body = {
						name: this.data.name,
						description: this.data.description,
						image: MediaSelect.id,
						fallback: FallbackMedia.id,
					}
					break;
				
				case 'upload':
					body = new FormData();
					body.append('name', this.data.name);
					body.append('description', this.data.description);
					if(MediaSelect.kind === 'upload'){
						body.append('image', MediaSelect.uploadedFile);
					}else{
						body.append('image', MediaSelect.id);
					}
					
					if(FallbackMedia.kind === 'upload'){
						body.append('fallback', FallbackMedia.uploadedFile);
					}else{
						body.append('fallback', FallbackMedia.id);
					}
					break;
				default:
					body = {
						name: this.data.name,
						description: this.data.description,
					}
			}
			
			const endpoint = `eventkind${this.id? '/' + this.id : ''}`;
			const method = this.id? 'PUT' : 'POST';
			
			const result = await restClient.ajax({
				endpoint: endpoint,
				method: method,
				data: body,
			});
			if(result && result.id){
				this.$emit('refreshTableItem', result);
				this.$emit('closeSuccess');
			}else{
				//Fehlermeldung
			}
		},
	},
	
};
</script>

<style scoped lang="scss">

</style>