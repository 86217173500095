<template>
	<div>
		
		<UserMenu/>
		
		<MainNav
			v-on:entry-clicked="setSubComponent($event)"
			v-bind:selectedComponent="subComponent">
		</MainNav>
		
		<component v-if="subComponent" v-bind:is="subComponent"></component>
		
		<a href="https://www.hassberge.de" target="_blank" style="width: 299px;">
			<img style="width:100px; position:absolute; bottom:0px; left:10px;"
				src="https://www.hassberge.de/fileadmin/templates/2018/public/images/logo.jpg">
		</a>
		
		<p style="font-size:12px; position:absolute; bottom:8px; right:10px;">
			Ein Produkt der <a href="https://nosgroup.de" target="_blank">NOSGROUP GmbH</a>
		</p>
	
	</div>
</template>

<script>
import UserMenu from './UserMenu.vue';

export default {
	name: 'Home',
	components: {UserMenu},
	data: function(){
		if(!sessionStorage.lastMainView){
			this.setSubComponent('MyEvents');
		}
		return {
			subComponent: sessionStorage.lastMainView,
		}
	},
	methods: {
		setSubComponent: function(evt){
			this.subComponent = evt;
			sessionStorage.lastMainView = evt;
		},
	},
};
</script>

<style scoped>

</style>