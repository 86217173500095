import * as XLSX from 'xlsx/xlsx.mjs';
import {toSqlDate} from './helper';

const COLS = [
	'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
];

function toDateObj(dateString){
	let parts = dateString.split('.');
	if(parts[2].length === 2){
		parts[2] = `20${parts[2]}`;
	}
	return new Date(parts.reverse().join('-'));
}

function excelRawDateToString(serial){
	const utc_days = Math.floor(serial - 25569);
	const utc_value = utc_days * 86400;
	const date_info = new Date(utc_value * 1000);
	return `${date_info.getDate()}.${date_info.getMonth() + 1}.${date_info.getFullYear()}`;
}

const Mapping = {
	'A': 'title',
	'B': 'kind',
	'C': 'date_start',
	'D': 'date_end',
	'E': 'time_start',
	'F': 'time_end',
	'G': 'description',
	'H': 'location',
	'I': 'registration',
	'J': 'accessible',
	'K': 'sustainability',
	'L': 'family_friendly',
	'M': 'with_cost',
	'N': 'price',
	'O': 'organisation',
	'P': 'web',
	'Q': 'mail',
	'R': 'phone_prefix',
	'S': 'phone',
	'T': 'facebook',
	'U': 'twitter',
	'V': 'instagram',
};


function createErrorObj(){
	return {
		price: [],
		eventKind: [],
		phone: [],
		title: [],
		organisation: [],
		mail: [],
		location: [],
		date_start: [],
		date_end: [],
	};
}

const tests = {
	price(row){
		if(row.price && !row.with_cost){
			row.errors.price.push('Veranstaltung hat einen Preis, ist aber nicht als Kostenpflichtig gekenzeichnet');
		}
	},
	eventKind(row){
		if(!row.eventKind){
			row.errors.eventKind.push('Unbekannte Veranstaltungsart');
		}
	},
	phone(row){
		if(row.phone !== '' && row.phone_prefix === ''){
			row.errors.phone.push('Vorwahl fehlt');
		}
		if(row.phone === '' && row.phone_prefix !== ''){
			row.errors.phone.push('Vorwahl ohne Telefonnummer');
		}
	},
	title(row){
		if(!row.title){
			row.errors.title.push('Titel fehlt');
		}
	},
	organisation(row){
		if(!row.organisation){
			row.errors.organisation.push('Veranstalter fehlt');
		}
	},
	emailOrPhone(row){
		if(!(row.phone || row.phone_prefix) && !row.mail){
			row.errors.mail.push('Email oder Telefon fehlt');
			row.errors.phone.push('Email oder Telefon fehlt');
		}
	},
	location(row){
		if(!row.location){
			row.errors.location.push('Ort fehlt');
		}
		if(!row.locationId){
			row.errors.location.push('unbekannter Ort');
		}
	},
	date(row){
		if(toDateObj(row.date_start) > toDateObj(row.date_end)){
			row.errors.date_start.push('Datumsfehler');
		}
		const check = toDateObj(row.date_start);
		if(check.toString() === 'Invalid Date'){
			row.errors.date_start.push('Invalides Datum');
		}
		
		const check_end = toDateObj(row.date_end);
		if(check_end.toString() === 'Invalid Date'){
			row.errors.date_end.push('Invalides Datum');
		}
	}
};

const EventKinds = {};

const Locations = {};

const Reader = {
	async read(){
		return new Promise((resolve, reject) => {
			const input = document.createElement('input');
			input.type = "file";
			
			input.onchange = async (evt) => {
				const files = evt.target.files;
				
				if(files.length !== 1){
					reject('Es darf nur eine Datei eingespielt werden');
				}
				const workbook = await this.getWorkbook(files[0]);
				this.parseEventKinds(workbook);
				this.parseLocations(workbook);
				const data = this.workbookToArray(workbook);
				resolve(data);
			};
			
			input.click();
		});
	},
	async getWorkbook(file){
		return XLSX.read(await file.arrayBuffer(), {});
	},
	parseEventKinds(workbook){
		const ref = workbook.Sheets.Veranstaltungsarten["!ref"];
		const range = this.getRange(ref);
		for(let row = range.startRow; row <= range.endRow; row++){
			let eventKind = workbook.Sheets.Veranstaltungsarten[`A${row}`].v;
			EventKinds[eventKind] = workbook.Sheets.Veranstaltungsarten[`B${row}`].v;
		}
	},
	parseLocations(workbook){
		const ref = workbook.Sheets.Orte["!ref"];
		const range = this.getRange(ref);
		for(let row = range.startRow; row <= range.endRow; row++){
			let location = workbook.Sheets.Orte[`A${row}`].v;
			Locations[location] = workbook.Sheets.Orte[`B${row}`].v;
		}
	},
	workbookToArray(workbook){
		const ref = workbook.Sheets.Vorlage["!ref"];
		const range = this.getRange(ref);
		const result = [];
		const maxColIndex = COLS.indexOf(range.endCol) + 1;
		const header = [];
		
		for(let i = range.startRow; i <= range.endRow; i++){
			let row = {
				errors: createErrorObj(),
				id: i,
			};
			for(let col = 0; col < maxColIndex; col++){
				let currentColl = `${COLS[col]}${i}`;
				if(i === 1){
					let content = workbook?.Sheets?.Vorlage[currentColl]?.v || '';
					header.push(content.replace(' (0/1)', ''));
				}else{
					let currentColl = `${COLS[col]}${i}`;
					if(COLS[col] === 'C' || COLS[col] === 'D'){
						let val = workbook?.Sheets?.Vorlage[currentColl]?.v;
						row[Mapping[COLS[col]]] = val? excelRawDateToString(val) : '';
					}else if(COLS[col] === 'E' || COLS[col] === 'F'){
						row[Mapping[COLS[col]]] = workbook?.Sheets?.Vorlage[currentColl]?.w;
					}else{
						let val = workbook?.Sheets?.Vorlage[currentColl]?.v;
						if(COLS[col] === 'N'){
							val = workbook?.Sheets?.Vorlage[currentColl]?.w;
						}
						if(typeof val === 'undefined' || val === null){
							val = '';
						}
						row[Mapping[COLS[col]]] = val;
					}
				}
			}
			if(i !== 1){
				row.eventKind = '';
				if(EventKinds[row.kind]){
					row.eventKind = EventKinds[row.kind];
				}
				if(Locations[row.location]){
					row.locationId = Locations[row.location];
				}
				if(row.date_start && !row.date_end){
					row.date_end = row.date_start;
				}
				result.push(this.validateRow(row));
			}
		}
		return {
			data: result,
			header,
		};
	},
	getRange(ref){
		const parts = ref.split(':');
		return {
			startCol: parts[0].substr(0, 1),
			startRow: parseInt(parts[0].substr(1)),
			endCol: parts[1].substr(0, 1),
			endRow: parseInt(parts[1].substr(1)),
		}
	},
	validateRow(row){
		for(let i in tests){
			tests[i](row);
		}
		
		return row;
	}
};

export default Reader;