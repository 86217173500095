import restClient from '../rest';
import Vue from '../../../node_modules/vue/dist/vue.min.js';
import CKEditor from '@ckeditor/ckeditor5-vue';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';

import '@ckeditor/ckeditor5-build-inline/build/translations/de';
/*
Vue.config.devtools = true;
Vue.config.debug = true;
Vue.config.silent = false;
*/
const CalendarApp = {
	renderer: null,
	client: restClient,
	ckeditor: {
		kind: InlineEditor,
		config: {
			language: 'de',
			toolbar: {
				items: [
					'bold',
					'italic',
					'underline',
					'|',
					'link',
					'bulletedList',
					'numberedList',
					'|',
					'undo',
					'redo',
				],
			}
		},
	},
	
	init: function(config){
		Vue.use(CKEditor);
		
		if(!'element' in config){
			throw 'Missing target Element in config';
		}
		
		const border = document.querySelector(config.element);
		const inner = document.createElement('div');
		inner.id = 'lraBorder';
		border.appendChild(inner);
		
		this.requireComponents();
		
		let component = 'Login';
		if(sessionStorage.lraToken){
			this.client.setToken(sessionStorage.lraToken);
			component = 'Home';
		}
		
		this.renderer = new Vue({
			el: '#lraBorder',
			data: function(){
				return {
					currentMain: component,
				};
			},
			template: `
				<div class="lraApp">
				<Spinner></Spinner>
				<component v-bind:is="currentMain"></component>
				</div>
			`,
		});
	},
	requireComponents: function(){
		const requireComponent = require.context(
			'./components',
			false,
			/[A-z]\w+.(vue|js)$/
		);
		
		requireComponent.keys().forEach(fileName => {
			// Get component config
			const componentConfig = requireComponent(fileName)
			
			// Get PascalCase name of component
			const componentName = fileName
				.split('/')
				.pop()
				.replace(/\.\w+$/, '');
			
			Vue.component(componentName, componentConfig.default || componentConfig);
		});
	},
	setMain: function(component){
		this.renderer.currentMain = component;
	},
	setUser: function(user){
		this.client.setToken(user.token);
		sessionStorage.lraUserName = user.name;
		sessionStorage.lraUserEmail = user.email;
		sessionStorage.lraUserGroupId = user.group_id;
		sessionStorage.lraUserGroupName = user.group_name;
	}
}


export default CalendarApp;